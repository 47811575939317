import { restaurantPropTypes, selectedLocationPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { A } from '../../typography';
import { Icon } from '../../icons';
import { RestaurantCalloutWindow } from '../restaurant-callout-window';

const NandosMapMarker = ({ coordinates, onRestaurantClick, restaurant, linkText, showRestaurantStatus }) => {
  const [showPopover, setShowPopover] = useState(null);

  const handleClick = event => {
    setShowPopover(event.currentTarget);
  };

  const handleClose = () => {
    setShowPopover(null);
  };

  return (
    <>
      <A onClick={handleClick}>
        <Icon name="barci" />
      </A>
      <RestaurantCalloutWindow
        coordinates={coordinates}
        handleClose={handleClose}
        linkText={linkText}
        onRestaurantClick={onRestaurantClick}
        open={Boolean(showPopover)}
        restaurant={restaurant}
        showPopover={showPopover}
        showRestaurantStatus={showRestaurantStatus}
      />
    </>
  );
};

NandosMapMarker.propTypes = {
  coordinates: selectedLocationPropTypes,
  linkText: PropTypes.string.isRequired,
  onRestaurantClick: PropTypes.func.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  showRestaurantStatus: PropTypes.bool,
};

NandosMapMarker.defaultProps = {
  coordinates: undefined,
  showRestaurantStatus: false,
};

export { NandosMapMarker };
