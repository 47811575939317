import { get, includes, isEmpty, values } from 'lodash';
import { observer } from 'mobx-react';
import { ORDER_TYPES } from '@nandosaus/constants';
import { PropTypes } from 'prop-types';
import { restaurantPropTypes } from '@nandosaus/prop-types';
import { useRootStore } from '@nandosaus/state-management';
import React, { useMemo } from 'react';

import { Alert } from '@components/alert';
import { Box, Flex } from '@components/grid';
import { DeliveryIssueAlert } from '@components/delivery-issue-alert';

import { LocationLink } from '../location-link';
import { OrderTypePicker } from '../order-type-picker';
import { ScheduleDelivery } from '../schedule-delivery';
import { SchedulePickUp } from '../schedule-pick-up';
import { TableNumberField } from '../../table-number-field';

const OrderSettingsScreen = ({
  onOrderTypeChange,
  onTableNumberChange,
  orderType,
  restaurant,
  showRestaurantFinder,
  showDeliveryAddressFinder,
  tableNumber,
  validationErrors,
}) => {
  const { DeliveryStore, OrderingContextStore } = useRootStore();

  const { availableOrderTypes, fieldMapFor } = OrderingContextStore;
  const fields = useMemo(() => fieldMapFor(orderType), [fieldMapFor, orderType]);

  const handleTableNumberChange = event => {
    const text = event.target.value;
    const input = text.trim();

    onTableNumberChange(isEmpty(input) ? undefined : input);
  };

  const handleOrderType = orderTypeItem => {
    const updatedOrderType = includes(values(ORDER_TYPES), orderTypeItem) ? orderTypeItem : ORDER_TYPES.PICK_UP;
    onOrderTypeChange(updatedOrderType);
  };

  const formattedStatusMessage = restaurant?.getFormattedStatusMessage({ orderType });
  const restaurantDisabled = formattedStatusMessage?.disableOptions || false;
  const showTableNumberField =
    orderType === ORDER_TYPES.DINE_IN && restaurant && get(restaurant, 'tableOrderingEnabled') && !restaurantDisabled;
  const showPickupSchedule =
    orderType === ORDER_TYPES.PICK_UP && restaurant && get(restaurant, 'pickUpEnabled') && !restaurantDisabled;
  const showDeliverySchedule = orderType === ORDER_TYPES.DELIVERY && DeliveryStore.deliveryAddress;

  return (
    <Flex flexDirection="column" px={{ _: '1.5rem', md: 1 }} py={2} position="relative" overflow="hidden">
      <Box position="static" zIndex={1}>
        <OrderTypePicker
          errorMessage={validationErrors.orderType}
          handleOrderType={handleOrderType}
          orderType={orderType}
          availableOrderTypes={availableOrderTypes}
        />
        {orderType && (
          <Box mb={1}>
            {orderType === ORDER_TYPES.DELIVERY && <DeliveryIssueAlert />}
            <LocationLink
              deliveryAddress={DeliveryStore.deliveryAddress}
              deliveryNotes={DeliveryStore.deliveryNotes}
              orderType={orderType}
              restaurant={restaurant}
              showDeliveryAddressFinder={showDeliveryAddressFinder}
              showRestaurantFinder={fields.restaurantId?.writable ? showRestaurantFinder : undefined}
            />
            {validationErrors.restaurant !== undefined && <Alert type="error">{validationErrors.restaurant}</Alert>}
            {validationErrors.deliveryAddress !== undefined && (
              <Alert type="error">{validationErrors.deliveryAddress}</Alert>
            )}
          </Box>
        )}
        {showPickupSchedule && <SchedulePickUp restaurant={restaurant} />}
        {showTableNumberField && (
          <>
            {fields.restaurantId?.writable && (
              <Alert mb="1.5rem" type="info">
                Please only select Dine in when you’re seated at your table in the restaurant.
              </Alert>
            )}
            <TableNumberField
              onChange={handleTableNumberChange}
              isError={validationErrors.tableNumber !== undefined}
              value={tableNumber}
              disabled={fields.tableNumber?.writable === false}
            />
            {validationErrors.tableNumber !== undefined && (
              <Alert mt={1} type="error">
                {validationErrors.tableNumber}
              </Alert>
            )}
          </>
        )}
        {showDeliverySchedule && <ScheduleDelivery />}
      </Box>
    </Flex>
  );
};

OrderSettingsScreen.propTypes = {
  showRestaurantFinder: PropTypes.func.isRequired,
  showDeliveryAddressFinder: PropTypes.func.isRequired,
  onOrderTypeChange: PropTypes.func.isRequired,
  onTableNumberChange: PropTypes.func.isRequired,
  orderType: PropTypes.oneOf(Object.values(ORDER_TYPES)),
  restaurant: restaurantPropTypes,
  tableNumber: PropTypes.string,
  validationErrors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

OrderSettingsScreen.defaultProps = {
  orderType: undefined,
  restaurant: undefined,
  tableNumber: undefined,
};

const ComposedOrderSettingScreen = observer(OrderSettingsScreen);
export { ComposedOrderSettingScreen as OrderSettingsScreen };
