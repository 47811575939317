import { restaurantPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';
import React from 'react';

import { Alert } from '../../alert';
import { Box, Flex } from '../../grid';
import { Icon } from '../../icons';
import { Label, Subheading } from '../../typography';

const DeliveryEstimate = ({ handleChangeOrderType, estimate }) => {
  if (estimate === null) {
    return (
      <Alert type="error" onLinkClick={handleChangeOrderType} linkText="Change order type">
        Unfortunately your address is currently outside of our deliverable area.
      </Alert>
    );
  }

  return (
    <Box backgroundColor="white">
      <Label fontWeight="normal" color="greyPrimary" mb={1}>
        Delivery from
      </Label>
      <Flex mb={1} alignItems="center">
        <Icon name="barci" height="30" />
        <Subheading variant={3} ml="0.5rem">
          {estimate.restaurant.name}
        </Subheading>
      </Flex>
      <Flex mb="0.5rem" alignItems="center">
        <Icon name="clock" />
        <Subheading variant={3} ml="0.5rem">
          Estimated delivery in {estimate.fromNow}
        </Subheading>
      </Flex>
    </Box>
  );
};

DeliveryEstimate.propTypes = {
  estimate: PropTypes.shape({
    fromNow: PropTypes.string.isRequired,
    restaurant: restaurantPropTypes.isRequired,
  }),
  handleChangeOrderType: PropTypes.func.isRequired,
};

DeliveryEstimate.defaultProps = {
  estimate: null,
};

export { DeliveryEstimate };
