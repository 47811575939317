import { DELIVERABILITY_STATUS, DELIVERY_ERROR_MESSAGES } from '@nandosaus/constants';
import PropTypes from 'prop-types';
import React from 'react';

import { Alert } from '../../alert';
import { Box } from '../../grid';

const DeliveryError = ({ onChangeAddressClick, onChangeOrderClick, onRetryClick, status }) => {
  const alertProps = {
    [DELIVERABILITY_STATUS.OUTSIDE_DELIVERY_AREA]: {
      linkProps: { title: 'Change order type', onClick: onChangeOrderClick },
      heading: 'Address outside delivery area',
      text: DELIVERY_ERROR_MESSAGES.OUTSIDE_DELIVERY_AREA,
    },
    [DELIVERABILITY_STATUS.COURIERS_UNAVAILABLE]: {
      linkProps: { title: 'Change order type', onClick: onChangeOrderClick },
      heading: 'No drivers available',
      text: DELIVERY_ERROR_MESSAGES.COURIERS_UNAVAILABLE,
    },
    [DELIVERABILITY_STATUS.OUTSIDE_DELIVERY_HOURS]: {
      linkProps: { title: 'Change order type', onClick: onChangeOrderClick },
      heading: 'Outside delivery hours',
      text: DELIVERY_ERROR_MESSAGES.OUTSIDE_DELIVERY_HOURS,
    },
    [DELIVERABILITY_STATUS.UNKNOWN]: {
      linkProps: { title: 'Try again', onClick: onRetryClick },
      heading: 'Unable to get estimate',
      text: DELIVERY_ERROR_MESSAGES.CANNOT_FETCH_DELIVERY_ESTIMATE,
    },
    [DELIVERABILITY_STATUS.ADDRESS_MISSING_POSTCODE]: {
      linkProps: { title: 'Please change address', onClick: onChangeAddressClick },
      heading: 'Address missing postcode',
      text: DELIVERY_ERROR_MESSAGES.ADDRESS_MISSING_POSTCODE,
    },
  }[status];

  if (alertProps === undefined) {
    return null;
  }

  return (
    <Box flex={1}>
      <Alert
        type="error"
        heading={alertProps.heading}
        onLinkClick={alertProps.linkProps.onClick}
        linkText={alertProps.linkProps.title}
      >
        {alertProps.text}
      </Alert>
    </Box>
  );
};

DeliveryError.propTypes = {
  onChangeAddressClick: PropTypes.func.isRequired,
  onChangeOrderClick: PropTypes.func.isRequired,
  onRetryClick: PropTypes.func.isRequired,
  status: PropTypes.oneOf(Object.values(DELIVERABILITY_STATUS)),
};

DeliveryError.defaultProps = {
  status: null,
};

export { DeliveryError };
