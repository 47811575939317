import { P } from '@components/typography';
import { Button } from '@components/button';
import { Users } from '@components/icons/users';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { fontWeight } from '@constants/font-weight';

const GroupDetailsButton = ({ onClick }) => {
  return (
    <Button variant="thinPrimary" onClick={onClick}>
      <P fontWeight={fontWeight.medium} variant={3} textTransform="none" mr="0.5rem">
        View group order
      </P>
      <Users height="20px" width="20px" />
    </Button>
  );
};

GroupDetailsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const ObservedGroupDetailsButton = observer(GroupDetailsButton);

export { ObservedGroupDetailsButton as GroupDetailsButton };
