import { get } from 'lodash';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { useRootStore } from '@nandosaus/state-management';
import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../../constants';
import { Flex } from '../../grid';
import { Icon } from '../../icons';
import { OrderSettingsSummaryItem } from '../order-settings-summary-item';
import { P } from '../../typography';

const RESTAURANT_NAME_PLACEHOLDER = 'Find a restaurant';

const OrderSettingsSummaryWrapper = styled.button`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 1.5rem;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    flex-wrap: nowrap;
    padding: 1rem;
  }
`;

const CompactWrapper = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled(Flex)`
  padding: ${({ isCompact }) => (isCompact ? '0' : '1rem')};
  margin-right: ${({ isCompact }) => (isCompact ? '0.5rem' : '1rem')};

  @media (min-width: ${breakpoints.md}) {
    padding: 0;
  }
`;

const OrderSettingsSummaryCta = ({ onClick, isCompact }) => {
  const { DeliveryStore, OrderingContextStore } = useRootStore();

  const { formattedDeliveryAddress, formattedDeliveryTimePreference, preferredDeliveryTime } = DeliveryStore;
  const { orderType, isDineIn, isPickUp, isDelivery } = OrderingContextStore;

  const WrapperComponent = isCompact ? CompactWrapper : OrderSettingsSummaryWrapper;

  if (!orderType) {
    return (
      <WrapperComponent type="button" data-testid="order-settings-summary" onClick={onClick}>
        <Flex alignItems="center" flexWrap="wrap" mb="-0.5rem">
          <P fontWeight={700} pr="1.5rem" mx="auto" mb="0.5rem" color="black">
            Start your order
          </P>
          <Flex mx="auto" mb="0.5rem">
            <Icon name="locationMarker" mr="0.5rem" fill="infoPrimary" />
            <P
              borderColor="infoPrimary"
              borderBottomStyle="dotted"
              borderWidth={1}
              fontWeight={700}
              color="infoPrimary"
              pt="4px"
            >
              Find a restaurant
            </P>
          </Flex>
        </Flex>
      </WrapperComponent>
    );
  }

  const summaryCopy = (() => {
    const { fulfilment, restaurant } = OrderingContextStore;

    if (isDineIn) {
      const { tableNumber } = fulfilment;
      const detailLabel = tableNumber ? `Table ${tableNumber}` : 'Table';

      return {
        detailLabel,
        detailIconName: 'markerWithLine',
        hasError: !tableNumber,
        iconName: 'cutlery',
        locationLabel: get(restaurant, 'name', null),
        locationPlaceholder: RESTAURANT_NAME_PLACEHOLDER,
        type: 'Dine in',
      };
    }

    if (isPickUp) {
      const { isAsap, formattedOrderTime } = fulfilment;

      return {
        detailLabel: formattedOrderTime,
        detailIconName: isAsap ? 'clock' : 'schedule',
        iconName: 'shoppingBag',
        locationLabel: get(restaurant, 'name', null),
        locationPlaceholder: RESTAURANT_NAME_PLACEHOLDER,
        type: 'Pick up',
      };
    }

    if (isDelivery) {
      return {
        detailLabel: formattedDeliveryTimePreference,
        detailIconName: preferredDeliveryTime === 'ASAP' ? 'clock' : 'schedule',
        iconName: 'delivery',
        locationLabel: formattedDeliveryAddress,
        locationPlaceholder: 'Set a location',
        type: 'Delivery',
      };
    }

    return undefined;
  })();

  return (
    <WrapperComponent type="button" onClick={onClick} data-testid="order-settings-summary">
      <Flex
        width={{ xs: isCompact ? 'auto' : '50%', md: 'fit-content' }}
        justifyContent={{ xs: 'flex-end', md: 'center' }}
        pr={{ xs: '0.5rem', md: 0 }}
      >
        <OrderSettingsSummaryItem
          hasSetValue={!!summaryCopy}
          iconName={summaryCopy.iconName}
          isCompact={isCompact}
          value={summaryCopy.type}
        />
      </Flex>
      <Flex
        width={{ xs: isCompact ? 'auto' : '50%', md: 'fit-content' }}
        justifyContent={{ xs: 'flex-start', md: 'center' }}
        pl={{ xs: '0.5rem', md: 0 }}
      >
        <OrderSettingsSummaryItem
          hasError={!!summaryCopy.hasError}
          hasSetValue={!!summaryCopy.detailLabel}
          iconName={summaryCopy.detailIconName}
          isCompact={isCompact}
          value={summaryCopy.detailLabel}
        />
      </Flex>
      <TextWrapper
        alignItems="center"
        isCompact={isCompact}
        justifyContent="center"
        width={{ xs: isCompact ? 'auto' : '100%', md: 'auto' }}
      >
        <P color="grey300" mx={isCompact ? '0.5rem' : 0} pb={isCompact ? '0.15rem' : 0} variant={isCompact ? 2 : 1}>
          {isDelivery ? 'to' : 'from'}
        </P>
      </TextWrapper>
      <Flex justifyContent="center" minWidth={0} width={{ xs: isCompact ? 'auto' : '100%', md: 'auto' }}>
        <OrderSettingsSummaryItem
          hasSetValue={!!summaryCopy.locationLabel}
          iconName="locationMarker"
          isCompact={isCompact}
          value={summaryCopy.locationLabel || summaryCopy.locationPlaceholder}
          truncateText
        />
      </Flex>
    </WrapperComponent>
  );
};

OrderSettingsSummaryCta.propTypes = {
  onClick: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
};

OrderSettingsSummaryCta.defaultProps = {
  isCompact: false,
};

const ObservedOrderSettingsSummaryCta = observer(OrderSettingsSummaryCta);
export { ObservedOrderSettingsSummaryCta as OrderSettingsSummaryCta };
