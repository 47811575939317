import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import { useIsMinWidth } from '@hooks/use-is-min-width';
import { HeadingWithLineHighlights } from '@components/heading-with-line-highlights';
import { Box } from '../../grid';
import { H2, P, H3 } from '../../typography';

const UpsellModalHeader = ({ heading, subheading, description }) => {
  const isDesktop = useIsMinWidth('lg');
  return (
    <Box textAlign="center" zIndex="10" position="relative" mb={{ xs: 1, lg: '0.5rem' }}>
      <HeadingWithLineHighlights
        heading={heading}
        py={0}
        mb="0.5rem"
        color="black"
        headingLevel={isDesktop ? 'h3' : 'h2'}
        highlightBackgroundColor="neonTeal400"
        overrideHeadingHighlighting={['highlightAllLines']}
      />
      <P variant={3}>{subheading}</P>
      {isDesktop ? <H3 mt="0.5rem">{description}</H3> : <H2 mt={1}>{description}</H2>}
    </Box>
  );
};

UpsellModalHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const ConnectedUpsellModalHeader = observer(UpsellModalHeader);

export { ConnectedUpsellModalHeader as UpsellModalHeader };
