import { INPUT_TYPE } from '@nandosaus/constants';
import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from '@components/grid';
import { Icon } from '@components/icons';
import { Label } from '@components/typography';
import { TextInput } from '@components/input/text-input';

const BACKSPACE_KEY = 'Backspace';

const TableNumberField = props => (
  <>
    <Label as="label" mb="1rem" fontWeight="normal" color="greyPrimary" htmlFor="tableNumberField">
      Table Number
    </Label>
    <Flex justifyContent="center" alignItems="center">
      <Icon mr="1rem" name="markerWithLine" />
      <TextInput
        {...props}
        name="tableNumberField"
        placeholder="1"
        required
        type={INPUT_TYPE.NUMBER}
        onKeyDown={e => {
          const nonNumericRegex = /[^0-9]/gi;

          if (nonNumericRegex.test(e.key.toString()) && e.key !== BACKSPACE_KEY) {
            e.preventDefault();
          }
        }}
      />
    </Flex>
  </>
);

TableNumberField.propTypes = {
  isError: PropTypes.bool.isRequired,
};

export { TableNumberField };
