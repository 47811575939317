import { useCallback, useEffect, useRef, useState } from 'react';

const DEFAULT_OBSERVER_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
};

/**
 * Sets up an IntersectionObserver to watch a given ref to a node.
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 *
 * This hook will then fire the given callback after the observed node has been on screen
 * for more than delayMilliseconds in time straight.
 *
 * If the node leaves the viewport, then the timer will reset.
 *
 * @param ref Node to observe
 * @param callback Callback to fire when node has been observed
 * @param delayMilliseconds Delay until callback fires
 * @param observerOptions IntersectionObserver options, see the page above for more info
 * @param fireOnlyOnce Whether to fire the callback only one time, false for repeated callbacks
 */
const useIntersectionObserver = ({ ref, callback, delayMilliseconds, observerOptions, fireOnlyOnce }) => {
  const [hasFired, setHasFired] = useState(false);
  const timer = useRef(null);

  const attemptFire = useCallback(() => {
    if (fireOnlyOnce && hasFired) {
      return;
    }

    setHasFired(true);
    callback();
  }, [callback, fireOnlyOnce, hasFired]);

  const stopTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);

  const startTimer = useCallback(() => {
    if (!timer.current) {
      timer.current = window.setTimeout(attemptFire, delayMilliseconds);
    }
  }, [attemptFire, delayMilliseconds]);

  const observerCallback = useCallback(
    ([entry]) => {
      if (entry.isIntersecting) {
        return startTimer();
      }

      return stopTimer();
    },
    [startTimer, stopTimer]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      ...DEFAULT_OBSERVER_OPTIONS,
      ...observerOptions,
    });

    const nodeToObserve = ref.current;

    if (nodeToObserve) {
      observer.observe(nodeToObserve);
    }

    return () => {
      if (nodeToObserve) {
        observer.unobserve(nodeToObserve);
      }
    };
  }, [observerCallback, observerOptions, ref, startTimer]);
};

export { useIntersectionObserver };
