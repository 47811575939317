import { useRootStore } from '@nandosaus/state-management';
import { useFeature } from '@hooks/use-feature';
import { ORDER_TYPES } from '@nandosaus/constants';

const useShouldEnableRecommendationEngine = () => {
  const { CartStore } = useRootStore();
  const recommendationEngineWhiteList = useFeature('recommendations-engine-restaurant-whitelist');
  return (
    CartStore.orderType === ORDER_TYPES.DINE_IN &&
    recommendationEngineWhiteList?.restaurants?.includes(CartStore.restaurantId)
  );
};

export { useShouldEnableRecommendationEngine };
