import { DEFAULT_COORDINATES } from '@nandosaus/constants';
import { selectedLocationPropTypes } from '@nandosaus/prop-types';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box } from '../grid';

const Map = ({ autoZoomCoordinates, coordinates, children, defaultZoom, height }) => {
  const [mapsApi, setMapsApi] = useState();

  useEffect(() => {
    if (mapsApi === undefined || autoZoomCoordinates.length === 0) {
      return;
    }

    const { map, maps } = mapsApi;

    const bounds = new maps.LatLngBounds();
    autoZoomCoordinates.forEach(({ latitude, longitude }) => {
      bounds.extend(new maps.LatLng(latitude, longitude));
    });

    // if only one map marker is present this bit of code adds a couple of extra points to ensure we don't zoom in too much.
    // taken from: https://stackoverflow.com/a/5345708
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
      const extendPoint2 = new maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    const padding = {
      bottom: 20,
      left: 20,
      right: 20,
      top: 20,
    };

    map.fitBounds(bounds, padding);
  }, [mapsApi, autoZoomCoordinates]);

  const handleMapsApiLoaded = loadedApi => {
    setMapsApi(loadedApi);
  };

  return (
    <Box data-testid="map" height={height} width="100%">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.googlePlacesApiKey }}
        defaultCenter={{
          lat: DEFAULT_COORDINATES.LATITUDE,
          lng: DEFAULT_COORDINATES.LONGITUDE,
        }}
        center={
          coordinates
            ? {
                lat: coordinates.latitude,
                lng: coordinates.longitude,
              }
            : null
        }
        defaultZoom={defaultZoom}
        onGoogleApiLoaded={handleMapsApiLoaded}
        options={{ clickableIcons: false, fullscreenControl: false }}
        yesIWantToUseGoogleMapApiInternals
      >
        {children}
      </GoogleMapReact>
    </Box>
  );
};

Map.propTypes = {
  autoZoomCoordinates: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    })
  ),
  coordinates: selectedLocationPropTypes,
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  defaultZoom: PropTypes.number,
};

Map.defaultProps = {
  autoZoomCoordinates: [],
  coordinates: PropTypes.shape({
    latitude: DEFAULT_COORDINATES.LATITUDE,
    longitude: DEFAULT_COORDINATES.LONGITUDE,
  }),
  height: '100vh',
  defaultZoom: 15,
};

export { Map };
