import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import { SelectInput } from '../../input/select-input';

const OrderTimePicker = ({ errorMessage, name, placeholder, defaultValue, isError, onChange, onFocus, options }) => {
  const [time, setTime] = useState(defaultValue);

  const onUpdate = event => {
    const { value } = event.target;

    setTime(value);
    onChange(value);
  };

  return (
    <SelectInput
      errorMessage={errorMessage}
      isError={isError}
      name={name}
      onChange={onUpdate}
      onFocus={onFocus}
      options={options}
      placeholder={placeholder}
      testId="order-time-picker"
      value={time}
      width="100%"
    />
  );
};

OrderTimePicker.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, text: PropTypes.string })).isRequired,
  placeholder: PropTypes.string.isRequired,
};

OrderTimePicker.defaultProps = {
  isError: false,
};

export { OrderTimePicker };
