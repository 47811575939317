import { isEmpty } from 'lodash';
import { useRootStore } from '@nandosaus/state-management';

const useBrazeUpsellContent = () => {
  const { BrazeStore, MenuStore } = useRootStore();
  const { upsellCard } = BrazeStore;
  // if there is no braze override, serve craft CMS/config upsell modal solution
  if (!upsellCard || isEmpty(upsellCard.products)) {
    const { menu } = MenuStore;
    const menuUpsellModal = menu?.upsellModal;

    if (!menuUpsellModal) {
      return {
        newProductIds: [],
        recommendedProducts: [],
        content: null,
      };
    }

    const { recommendedProducts, heading, subheading, description, disclaimer, cta } = menuUpsellModal;

    return {
      newProductIds: [],
      recommendedProducts,
      content: {
        heading,
        subheading,
        description,
        cta,
        disclaimer,
      },
    };
  }

  return {
    newProductIds: upsellCard?.newProducts,
    recommendedProducts: upsellCard.products,
    // within Braze, only heading and CTA are content manageable
    content: {
      heading: upsellCard.heading ?? null,
      subheading: 'We’ve added them to your cart',
      description: 'What about these?',
      cta: upsellCard.cta ?? null,
      disclaimer: 'You can always edit your order before you confirm',
    },
    brazeLogImpression: upsellCard?.brazeLogImpression,
    brazeLogClick: itemPlu => {
      if (upsellCard.plus?.includes(itemPlu)) {
        upsellCard.brazeLogClick();
      }
    },
  };
};

export { useBrazeUpsellContent };
