import PropTypes from 'prop-types';
import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { colors } from '@nandosaus/constants';

const QRCode = ({ url, backgroundColor, color, size, level }) => (
  <QRCodeSVG value={url} bgColor={backgroundColor} fgColor={color} size={size} level={level} />
);

QRCode.propTypes = {
  url: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  level: PropTypes.oneOf(['L', 'M', 'Q', 'H']),
  color: PropTypes.string,
  size: PropTypes.number,
};

QRCode.defaultProps = {
  backgroundColor: 'transparent',
  color: colors.black,
  size: 128,
  level: 'L',
};

export { QRCode };
