import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { useIsMinWidth } from '@hooks/use-is-min-width';
import { HeadingWithLineHighlights } from '@components/heading-with-line-highlights';
import { Flex } from '@components/grid';
import { Icon } from '@components/icons';

const CartPreviewHeader = ({ heading, hidePreview }) => {
  const isDesktop = useIsMinWidth('lg');

  return (
    <Flex flexDirection="column" alignItems="center" p={1} pt={{ xs: 1, lg: 0 }}>
      <Flex mb="0.5rem" justifyContent="center" width="100%" alignItems="center">
        <Flex flex={1} />
        <HeadingWithLineHighlights
          heading={heading}
          mt={2}
          mb={0}
          color="black"
          headingLevel={isDesktop ? 'h3' : 'h2'}
          highlightBackgroundColor="neonTeal400"
          overrideHeadingHighlighting={['highlightAllLines']}
        />
        <Flex flex={1} justifyContent="flex-end">
          <button type="button" onClick={hidePreview} aria-label="Close">
            <Icon name="cross" width="24px" height="24px" fill="greyPrimary" />
          </button>
        </Flex>
      </Flex>
    </Flex>
  );
};

CartPreviewHeader.propTypes = {
  hidePreview: PropTypes.func.isRequired,
  heading: PropTypes.string,
};

CartPreviewHeader.defaultProps = {
  heading: 'Your order',
};

const ComposedCartPreviewHeader = observer(CartPreviewHeader);
export { ComposedCartPreviewHeader as CartPreviewHeader };
