import { ALERT_TYPES, ANALYTICS_EVENTS } from '@nandosaus/constants';
import React from 'react';
import styled from 'styled-components';

import { Alert } from '../../alert';
import { analytics } from '../../../analytics';
import { Flex } from '../../grid';
import { Icon } from '../../icons';
import { Link } from '../../link';
import { navigation } from '../../../constants';
import { setLandingPage } from '../../../utils/auth';

const TYPE = ALERT_TYPES.INFO;

const PeriPerksAlert = () => (
  <Alert
    heading="Are you a PERi Perks Member?"
    type={TYPE}
    icon={
      <Flex mr="0.5rem">
        <Icon name="periPerksLogo" stroke="none" />
      </Flex>
    }
  >
    {/* @TODO: Preserve the drawer open after navigation */}
    <StyledLink
      href={navigation.SIGN_IN}
      variant={3}
      onClick={() => {
        analytics.track(ANALYTICS_EVENTS.USER_SIGN_IN_CLICKED, {
          location: 'Cart',
        });
        setLandingPage(navigation.MENU);
      }}
    >
      Sign in
    </StyledLink>{' '}
    or{' '}
    <StyledLink
      href={navigation.CREATE_ACCOUNT}
      variant={3}
      onClick={() => {
        analytics.track(ANALYTICS_EVENTS.USER_SIGN_UP_CLICKED, { location: 'Cart' });
        setLandingPage(navigation.MENU);
      }}
    >
      create an account
    </StyledLink>{' '}
    to enjoy exclusive offers and easy re-ordering.
  </Alert>
);

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: inherit;
`;

export { PeriPerksAlert };
