import { kebabCase, lowerCase, map } from 'lodash';

const createNavId = inPageNavLabel => {
  if (!inPageNavLabel) {
    return null;
  }

  return `#${kebabCase(lowerCase(inPageNavLabel))}`;
};

const createNavIds = blocks => {
  const blocksWithId = map(blocks, block => {
    if (!block.id) {
      return block;
    }
    return {
      ...block,
      id: createNavId(block.id),
      label: block.id,
    };
  });
  return blocksWithId;
};

export { createNavId, createNavIds };
