import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from '@components/grid';
import { Label } from '@components/typography';
import { Button } from '@components/button';
import { BUTTON_VARIANTS } from '@components/button/constants';
import { Icon } from '@components/icons';

const ShareButton = ({ url, title, text, buttonText, onClick }) => {
  // This button utilize Web Share API to open the native share interface
  // Unfortunately it doesn't available on all platform yet
  // to avoid error in unsupported platforms, we won't show the share button on unsupported platforms
  if (!navigator.canShare || !navigator.share) {
    return null;
  }

  const isShareable = navigator.canShare({ url, title, text });
  if (!isShareable) {
    return null;
  }

  const handleClick = () => {
    navigator.share({ url, title, text }).catch(() => null);
    onClick();
  };

  return (
    <Flex pt="1" justifyContent="center">
      <Button type="button" height="55px" variant={BUTTON_VARIANTS.LINK} onClick={handleClick} px="0.5rem">
        <Flex alignItems="center">
          <Icon name="share" />
          <Label ml="0.5rem">{buttonText}</Label>
        </Flex>
      </Button>
    </Flex>
  );
};

ShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ShareButton.defaultProps = {
  text: '',
  title: '',
  onClick: () => {},
};

export { ShareButton };
