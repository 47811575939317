import { CardWrapper } from '@components/card/card-wrapper';
import { Box } from '@components/grid';
import { SkeletonLoader } from '@components/skeleton-loader';
import PropTypes from 'prop-types';
import { PROMO_CARD_HEIGHT_BREAKPOINTS } from '../promotion-card';

const PromotionCardSkeleton = ({ shouldKeepVertical, role, aspectRatio }) => {
  return (
    <CardWrapper role={role} style={{ aspectRatio }} height={PROMO_CARD_HEIGHT_BREAKPOINTS}>
      <Box
        flexWrap={{ xs: 'no-wrap' }}
        flexDirection={{ xs: shouldKeepVertical ? 'column' : 'row', md: 'column' }}
        borderRadius={12}
        overflow="hidden"
        height="100%"
        width="100%"
      >
        <SkeletonLoader height="100%" width="100%" />
      </Box>
    </CardWrapper>
  );
};

PromotionCardSkeleton.propTypes = {
  shouldKeepVertical: PropTypes.bool,
  role: PropTypes.string,
  aspectRatio: PropTypes.string,
};

PromotionCardSkeleton.defaultProps = {
  shouldKeepVertical: true,
  role: '',
  aspectRatio: null,
};

export { PromotionCardSkeleton };
