import { restaurantPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';

import { Alert } from '../../alert';
import { Box } from '../../grid';
import { orderErrorPropTypes } from '../../../prop-types/order-error';
import { PeriPerksAlert } from '../peri-perks-cta';

const CartAlert = ({ errors, hasNetworkError, restaurant, isSignedIn, isGuestOrderingAvailable }) => {
  const validRestaurantSelected = !!restaurant;

  if (hasNetworkError) {
    return (
      <Box mx="1rem" mb="1rem">
        <Alert type="error">Network Error </Alert>
      </Box>
    );
  }

  if (errors.length > 0) {
    return errors.map(error => (
      <Box key={error.id} mx="1rem" mb="1rem">
        <Alert heading={error.heading} type="error">
          {error.message}
        </Alert>
      </Box>
    ));
  }

  if (validRestaurantSelected && !isSignedIn && !isGuestOrderingAvailable) {
    return (
      <Box mx="1rem" mb="1rem">
        <PeriPerksAlert />
      </Box>
    );
  }

  return null;
};

CartAlert.propTypes = {
  errors: PropTypes.arrayOf(orderErrorPropTypes).isRequired,
  hasNetworkError: PropTypes.bool,
  isSignedIn: PropTypes.bool,
  isGuestOrderingAvailable: PropTypes.bool,
  restaurant: restaurantPropTypes,
};

CartAlert.defaultProps = {
  hasNetworkError: false,
  isSignedIn: true,
  restaurant: null,
  isGuestOrderingAvailable: false,
};

export { CartAlert };
