import { Alert } from '../alert';
import { useFeature } from '../../hooks/use-feature';

const DeliveryIssueAlert = () => {
  const showAlert = useFeature('delivery-issue-alert');

  if (!showAlert) {
    return null;
  }

  return (
    <Alert mt="0.5rem" mb={1} type="info" heading="System maintenance update for delivery orders 10pm (AEDT) tonight.">
      We are performing a system update from 10pm (AEDT) to improve your digital delivery experience with Nando’s. If
      you encounter any difficulty placing your delivery order via the Nando’s Web or Application, then please head over
      to your favourite 3rd party food delivery provider to complete your order. If you’ve already placed an order, we
      are busy getting that ready and it’ll be with you soon.
    </Alert>
  );
};

DeliveryIssueAlert.propTypes = {};

export { DeliveryIssueAlert };
