import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Box, Flex } from '../../grid';
import { Button } from '../../button';
import { DeliverySponsorLogo } from '../../delivery-sponsor-logo';
import { P } from '../../typography';

const DeliveryDiscountCta = ({ isDelivery, isDiscountActive, isDiscountApplied, deliverySponsor, onClick }) => {
  if (!isDelivery || !deliverySponsor || !isDiscountActive || isDiscountApplied) {
    return null;
  }

  const sponsorLogo = <DeliverySponsorLogo deliverySponsor={deliverySponsor} />;

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      borderBottomColor="grey50"
      borderBottomWidth="4px"
      borderBottomStyle="solid"
    >
      <Flex alignItems={{ xs: 'flex-start', lg: 'center' }} flexDirection={{ xs: 'column', lg: 'row' }} pl={1}>
        <P variant={3} fontWeight="bold">
          Get Free Delivery with
        </P>
        <Box ml={{ xs: 0, lg: '0.5rem' }}>{sponsorLogo || <P variant={3}>{deliverySponsor.name}</P>}</Box>
      </Flex>
      <Button onClick={onClick} variant="secondary" mr={1} my="1.25rem" width="30%">
        Explore menu
      </Button>
    </Flex>
  );
};

DeliveryDiscountCta.propTypes = {
  isDelivery: PropTypes.bool,
  isDiscountActive: PropTypes.bool,
  isDiscountApplied: PropTypes.bool,
  deliverySponsor: PropTypes.shape({
    handle: PropTypes.string,
    name: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
};

DeliveryDiscountCta.defaultProps = {
  isDelivery: false,
  isDiscountActive: null,
  isDiscountApplied: null,
  deliverySponsor: null,
};

const ComposedDeliveryDiscountCta = observer(DeliveryDiscountCta);

export { ComposedDeliveryDiscountCta as DeliveryDiscountCta };
