import { useIntersectionObserver } from '@hooks/use-intersection-observer';
import { useCallback, useRef } from 'react';
import { analytics } from '../../analytics';

/**
 * Uses the IntersectionObserver API and useIntersectionObserver() hook to fire off specifically
 * tracking events since it is a common use-case.
 *
 * Will track the intersection of the given ref, and track analytics against the given eventName
 * if the ref has been on screen for more than delayMilliseconds duration.
 *
 * @param ref The ref to watch
 * @param eventName The event name to track in analytics
 * @param delayMilliseconds Milliseconds the ref needs to be onscreen for to fire the analytics event.
 */
const useTrackImpression = ({ ref, eventName, delayMilliseconds }) => {
  const trackerCallback = useCallback(() => {
    // Allow for tracker hooks to be put into components without necessarily being
    // fulfilled. Otherwise this would cause rendering issues for React.
    if (!eventName) {
      return;
    }

    analytics.track(eventName);
  }, [eventName]);

  return useIntersectionObserver({
    ref,
    delayMilliseconds: delayMilliseconds ?? 1000,
    fireOnlyOnce: true,
    callback: trackerCallback,
  });
};

const useOnDisplay = ({ delayMilliseconds, callback }) => {
  const ref = useRef(null);
  const trackerCallback = useCallback(() => {
    if (ref) callback?.();
  }, [callback]);

  useIntersectionObserver({
    ref,
    delayMilliseconds: delayMilliseconds ?? 1000,
    fireOnlyOnce: true,
    callback: trackerCallback,
  });

  return ref;
};

export { useTrackImpression, useOnDisplay };
