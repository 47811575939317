import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Flex } from '../../grid';
import { Icon } from '../../icons';
import { P } from '../../typography';

const getStatusColor = (hasError, hasSetValue, theme = null) => {
  if (hasError) {
    return theme?.colors?.errorPrimary ?? 'errorPrimary';
  }

  if (hasSetValue) {
    return theme?.colors?.black ?? 'black';
  }

  return theme?.colors?.infoPrimary ?? 'infoPrimary';
};

const OrderSettingsSummaryItem = ({ hasError, hasSetValue, iconName, isCompact, value, truncateText }) => (
  <Flex mr={isCompact ? '0.5rem' : 1} minWidth={0}>
    <Icon
      name={iconName}
      fill={getStatusColor(hasError, hasSetValue)}
      mr={isCompact ? '4px' : '8px'}
      mt={{ xs: '-2px', md: 0 }}
      width={isCompact ? '16px' : '24px'}
      height={isCompact ? '16px' : '24px'}
    />
    <StyledActionLabel variant={isCompact ? 4 : 1} {...{ truncateText, hasError, hasSetValue, isCompact }}>
      {value}
    </StyledActionLabel>
  </Flex>
);

const StyledActionLabel = styled(P)`
  color: ${({ hasError, hasSetValue, theme }) => getStatusColor(hasError, hasSetValue, theme)};
  font-weight: ${({ hasSetValue }) => (hasSetValue ? 'bold' : 'none')};
  white-space: nowrap;
  line-height: ${({ isCompact }) => (isCompact ? '14px' : '24px')};
  border-bottom: 1px dotted ${({ hasError, hasSetValue, theme }) => getStatusColor(hasError, hasSetValue, theme)};

  ${({ truncateText }) =>
    truncateText &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

OrderSettingsSummaryItem.propTypes = {
  truncateText: PropTypes.bool,
  hasError: PropTypes.bool,
  hasSetValue: PropTypes.bool.isRequired,
  iconName: PropTypes.string.isRequired,
  isCompact: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

OrderSettingsSummaryItem.defaultProps = {
  hasError: false,
  truncateText: false,
  isCompact: false,
};

export { OrderSettingsSummaryItem };
