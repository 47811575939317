import { restaurantsArrayPropTypes, selectedLocationPropTypes } from '@nandosaus/prop-types';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import { EmptyMap } from './empty-map';
import { ErrorBoundary } from '../error-boundary';
import { Map } from '../map';
import { NandosMapMarker } from './nandos-map-marker';
import getRestaurantPath from '../../utils/get-restaurant-path';

const RestaurantMap = ({ coordinates, height, linkText, onRestaurantClick, restaurants, showRestaurantStatus }) => {
  const router = useRouter();
  if (coordinates === undefined) {
    return <EmptyMap height={height} />;
  }

  const redirect = restaurant => {
    const restaurantPath = getRestaurantPath(restaurant);
    router.push(restaurantPath);
  };

  const autoZoomCoordinates = restaurants.map(r => ({ latitude: r.latitude, longitude: r.longitude }));

  return (
    <ErrorBoundary>
      <Map autoZoomCoordinates={autoZoomCoordinates} coordinates={coordinates} height={height}>
        {restaurants.map(restaurant => (
          <NandosMapMarker
            coordinates={coordinates}
            key={restaurant.id}
            lat={restaurant.latitude}
            linkText={linkText}
            lng={restaurant.longitude}
            onRestaurantClick={onRestaurantClick ?? redirect}
            restaurant={restaurant}
            showRestaurantStatus={showRestaurantStatus}
          />
        ))}
      </Map>
    </ErrorBoundary>
  );
};

RestaurantMap.propTypes = {
  coordinates: selectedLocationPropTypes,
  height: PropTypes.string,
  linkText: PropTypes.string.isRequired,
  onRestaurantClick: PropTypes.func.isRequired,
  restaurants: restaurantsArrayPropTypes,
  showRestaurantStatus: PropTypes.bool,
};

RestaurantMap.defaultProps = {
  coordinates: undefined,
  showRestaurantStatus: false,
  height: '300px',
  restaurants: [],
};

export { RestaurantMap };
