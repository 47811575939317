import { propType as StyledSystemPropType } from '@styled-system/prop-types';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { Flex } from '../grid';
import { Icon } from '../icons';

const Container = styled(Flex)`
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }

  &:active {
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.grey100 : theme.colors.grey200)};
  }
`;

const ClickableIcon = styled(Icon)`
  &:active {
    fill: ${({ theme, disabled }) => (disabled ? theme.colors.grey300 : theme.colors.grey600)};
  }
`;

const IconButton = ({ disabled, wrapperSize, iconSize, iconName, onClick, ...otherProps }) => {
  return (
    <Container
      display="block"
      as="button"
      justifyContent="center"
      alignItems="center"
      disabled={disabled}
      borderRadius="100%"
      aria-label={iconName}
      onClick={onClick}
      cursor="pointer"
      boxShadow={disabled ? '' : '0px 0px 4px 0px rgba(0, 0, 0, 0.29)'}
      pointerEvents={disabled ? 'none' : undefined}
      backgroundColor={disabled ? colors.grey100 : colors.white}
      size={wrapperSize}
      {...otherProps}
    >
      <ClickableIcon
        name={iconName}
        fill={disabled ? colors.grey300 : colors.black}
        size={iconSize}
        disabled={disabled}
      />
    </Container>
  );
};

IconButton.propTypes = {
  disabled: PropTypes.bool,
  wrapperSize: StyledSystemPropType,
  iconSize: StyledSystemPropType,
  iconName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

IconButton.defaultProps = {
  disabled: false,
  wrapperSize: '48px',
  iconSize: '32px',
  iconName: 'plus',
};

export { IconButton };
