import { colors } from '@nandosaus/constants';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';

import { Box, Flex } from '@components/grid';
import { Label, P } from '@components/typography';
import { bodySize, inputSize } from '@constants';
import { Button } from '@components/button';
import { BUTTON_VARIANTS } from '@components/button/constants';

const SuccessBorderedFlex = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.successPrimary};
  min-height: ${inputSize.sm};
  font-size: ${bodySize.L3M2S1};
  padding: 0.5rem;
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin-right: 0.5rem;
`;

const Url = styled(P)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 1rem;
`;

const CopyTextButton = ({ onClick, text, label }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const handleOnClick = () => {
    onClick();
    navigator.clipboard.writeText(text);
    setIsLinkCopied(true);
  };

  return (
    <Box px="2rem">
      {label && (
        <Label fontWeight="normal" color="greyPrimary" mb="0.2rem">
          {label}
        </Label>
      )}
      <SuccessBorderedFlex justifyContent="space-between" alignItems="center" width="100%">
        <Url variant={2} textTransform="none" color={colors.black}>
          {text}
        </Url>
        <StyledButton variant={BUTTON_VARIANTS.LINK} onClick={handleOnClick}>
          <P variant={2} textTransform="none" color={colors.blue700} fontWeight={400}>
            {isLinkCopied ? 'Copied' : 'Copy Link'}
          </P>
        </StyledButton>
      </SuccessBorderedFlex>
    </Box>
  );
};

CopyTextButton.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

CopyTextButton.defaultProps = {
  label: null,
  onClick: () => null,
};

export { CopyTextButton };
