import { noop } from 'lodash';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { useRootStore } from '@nandosaus/state-management';
import React, { useEffect } from 'react';

import { ErrorBoundary } from '../error-boundary';
import { OrderSettingsSummaryCta } from './order-settings-summary-cta';

const OrderSettingsSummary = ({ isCompact, onClick }) => {
  const { RestaurantStore } = useRootStore();

  useEffect(() => {
    if (!RestaurantStore.loaded && !RestaurantStore.loading) {
      RestaurantStore.loadRestaurants();
    }
  }, [RestaurantStore]);

  return (
    <ErrorBoundary>
      <OrderSettingsSummaryCta onClick={onClick} isCompact={isCompact} />
    </ErrorBoundary>
  );
};

OrderSettingsSummary.propTypes = {
  isCompact: PropTypes.bool,
  onClick: PropTypes.func,
};

OrderSettingsSummary.defaultProps = {
  isCompact: false,
  onClick: noop,
};

const ComposedOrderSettingSummary = observer(OrderSettingsSummary);
export { ComposedOrderSettingSummary as OrderSettingsSummary };
