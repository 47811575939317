import { ANALYTICS_EVENTS, ORDER_TYPES, ORDER_TYPES_LABELS } from '@nandosaus/constants/lib/constants';
import { useRootStore } from '@nandosaus/state-management';
import { isString, some } from 'lodash';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { useState } from 'react';

import { analytics } from '../../analytics';
import { useGoogleMaps } from '../../hooks/use-google-maps';
import { Button } from '../button';
import { DeliveryAddressModal } from '../delivery-address-modal';
import { ErrorBoundary } from '../error-boundary';
import { Modal } from '../modal';
import { OrderSettingsScreen } from './order-settings-screen';
import { RestaurantFinderScreen } from './restaurant-finder-screen';

const MODAL_SCREENS = {
  DEFAULT: 'default',
  RESTAURANT_FINDER: 'finder',
  DELIVERY_FINDER: 'delivery',
};

const OrderSettingsModal = ({ onClose }) => {
  const [modalScreen, setModalScreen] = useState(MODAL_SCREENS.DEFAULT);
  const { CartStore, DeliveryStore, OrderingContextStore } = useRootStore();
  const [settings, setSettings] = useState({
    orderType: OrderingContextStore.orderType,
    restaurant: OrderingContextStore.restaurant,
    tableNumber: OrderingContextStore.fulfilment?.tableNumber,
  });
  const [validationErrors, setValidationErrors] = useState({});

  // Preload Google Maps library
  useGoogleMaps();

  /*
    Update a given setting in local state and revalidate the changed field
    to see if any additional errors. If changing order type then all
    settings are revalidated to ensure old errors from no-longer relevant
    fields pollute the errors.
  */
  const updateSetting = (key, value) => {
    const newSettings = {
      ...settings,
      [key]: value,
    };

    setSettings(newSettings);

    const errors = OrderingContextStore.validateSettings(newSettings);

    if (key === 'orderType') {
      setValidationErrors(errors);
    } else {
      setValidationErrors({
        ...validationErrors,
        [key]: errors[key],
      });
    }
  };

  const handleDismiss = () => {
    analytics.track(ANALYTICS_EVENTS.ORDER_SETTINGS_DISMISSED);
    onClose();
  };

  if (modalScreen === MODAL_SCREENS.RESTAURANT_FINDER) {
    return (
      <Modal
        handleBack={() => setModalScreen(MODAL_SCREENS.DEFAULT)}
        handleClose={handleDismiss}
        modalTitle="Find a Nando’s"
        open
        webHeight="800px"
      >
        <RestaurantFinderScreen
          orderType={settings.orderType}
          onSelect={newRestaurant => {
            updateSetting('restaurant', newRestaurant);

            if (settings.orderType === ORDER_TYPES.PICK_UP) {
              CartStore.updateDefaultOrderEstimate(newRestaurant.id);
            }

            setModalScreen(MODAL_SCREENS.DEFAULT);
          }}
        />
      </Modal>
    );
  }

  if (modalScreen === MODAL_SCREENS.DELIVERY_FINDER) {
    return (
      <DeliveryAddressModal
        onCancel={() => setModalScreen(MODAL_SCREENS.DEFAULT)}
        onClose={handleDismiss}
        onConfirm={() => {
          const { shortestDeliveryTime } = DeliveryStore;

          const errors = OrderingContextStore.validateSettings({
            ...settings,
            restaurant: shortestDeliveryTime.restaurant,
          });

          setValidationErrors(errors);
          setModalScreen(MODAL_SCREENS.DEFAULT);
        }}
        open
      />
    );
  }

  const handleConfirm = () => {
    const { errors, success } = OrderingContextStore.validateThenUpdateSettings(settings);

    setValidationErrors(errors);

    if (success) {
      analytics.track(ANALYTICS_EVENTS.ORDER_SETTINGS_UPDATED, {
        orderType: ORDER_TYPES_LABELS[OrderingContextStore.orderType],
        schedule: OrderingContextStore.fulfilment?.isAsap ? 'ASAP' : 'Scheduled',
        restaurant: settings.restaurant?.name,
      });

      onClose();
    }
  };

  const handleTableNumberChange = newTableNumber => {
    updateSetting('tableNumber', newTableNumber);
  };

  return (
    <Modal
      buttons={{
        primary: (
          <Button disabled={some(validationErrors, isString)} onClick={handleConfirm} width={{ _: '100%', md: 'auto' }}>
            Save and Continue
          </Button>
        ),
      }}
      handleClose={handleDismiss}
      hasFooterDivider
      modalTitle="Order Details"
      open
      webHeight="800px"
      backgroundImage="/static/images/background.svg"
    >
      <ErrorBoundary>
        <OrderSettingsScreen
          onOrderTypeChange={newOrderType => {
            updateSetting('orderType', newOrderType);
          }}
          onTableNumberChange={handleTableNumberChange}
          orderType={settings.orderType}
          restaurant={settings.restaurant}
          showRestaurantFinder={() => setModalScreen(MODAL_SCREENS.RESTAURANT_FINDER)}
          showDeliveryAddressFinder={() => setModalScreen(MODAL_SCREENS.DELIVERY_FINDER)}
          tableNumber={settings.tableNumber}
          validationErrors={validationErrors}
        />
      </ErrorBoundary>
    </Modal>
  );
};

OrderSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const ComposedOrderSettingModal = observer(OrderSettingsModal);
export { ComposedOrderSettingModal as OrderSettingsModal };
