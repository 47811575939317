import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Label } from '../typography';

const Tabs = ({ options, activeTab, selectTab }) => (
  <>
    {options.map(({ label, value }) => (
      <Tab onClick={() => selectTab(value)} key={value} active={activeTab === value}>
        <TabText active={activeTab === value}>{label}</TabText>
      </Tab>
    ))}
  </>
);

const Tab = styled.div`
  cursor: pointer;
  width: fit-content;
  border-bottom: ${({ active }) => (active ? '2px' : '0px')} solid ${({ theme }) => theme.colors.neonTeal400};
  padding-bottom: 0.5rem;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const TabText = styled(Label)`
  color: ${({ active, theme }) => (active ? theme.colors.black : theme.colors.grey300)};
`;

Tabs.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  selectTab: PropTypes.func.isRequired,
};

export { Tabs };
