import { observer } from 'mobx-react';
import { ORDER_TYPES } from '@nandosaus/constants';
import { PropTypes } from 'prop-types';
import { restaurantPropTypes } from '@nandosaus/prop-types';
import React from 'react';

import { A, P, Subheading } from '../../typography';
import { Button } from '../../button';
import { deliveryAddressPropTypes } from '../../../prop-types';
import { Flex } from '../../grid';
import { Icon } from '../../icons';
import { OrderSettingsOption } from '../order-settings-option';
import { TextClamp } from '../../text-clamp';

const LocationLink = ({
  deliveryAddress,
  deliveryNotes,
  orderType,
  restaurant,
  showDeliveryAddressFinder,
  showRestaurantFinder,
}) => {
  if (orderType === ORDER_TYPES.DELIVERY && deliveryAddress) {
    return (
      <OrderSettingsOption label="Delivery Address" iconName="locationMarker" onClick={showDeliveryAddressFinder}>
        <Flex flex={1} flexWrap="wrap" flexDirection="column" color="black">
          <Subheading mb={{ xs: '0.25rem', md: 0 }} variant={3}>
            {deliveryAddress.formattedStreet}
          </Subheading>
          <P variant={3}>{deliveryAddress.formattedSuburbStatePostcode}</P>
          {deliveryNotes && (
            <P variant={4} color="grey500">
              <TextClamp lines={1}>{deliveryNotes}</TextClamp>
            </P>
          )}
        </Flex>
        <Flex justifyContent="flex-end" ml={1}>
          <A onClick={showDeliveryAddressFinder}>Change</A>
        </Flex>
      </OrderSettingsOption>
    );
  }

  if (orderType === ORDER_TYPES.DELIVERY) {
    return (
      <OrderSettingsOption label="Delivery Address">
        <Button variant="secondary" onClick={showDeliveryAddressFinder} isFullWidth>
          <Icon mr={{ _: 1, md: '0.5rem' }} name="locationMarker" />
          Set delivery address
        </Button>
      </OrderSettingsOption>
    );
  }

  if (restaurant) {
    const formattedStatusMessage = restaurant.getFormattedStatusMessage({ orderType });

    return (
      <OrderSettingsOption label="Restaurant" iconName="barci" onClick={showRestaurantFinder}>
        <Flex flex={1} flexWrap="wrap" flexDirection="column">
          <Subheading variant={3} mb={{ xs: '0.25rem', md: 0 }} color="black">
            {restaurant.name}
          </Subheading>
          {formattedStatusMessage && (
            <P variant={3} color={formattedStatusMessage.fontColour}>
              {formattedStatusMessage.subtitle}
            </P>
          )}
        </Flex>
        {showRestaurantFinder && (
          <Flex justifyContent="flex-end">
            <A onClick={showRestaurantFinder}>Change</A>
          </Flex>
        )}
      </OrderSettingsOption>
    );
  }

  return (
    <OrderSettingsOption label="Restaurant">
      <Button variant="secondary" onClick={showRestaurantFinder} isFullWidth>
        <Icon mr={{ _: 1, md: '0.5rem' }} name="barci" />
        Find a restaurant
      </Button>
    </OrderSettingsOption>
  );
};

LocationLink.propTypes = {
  deliveryAddress: deliveryAddressPropTypes,
  deliveryNotes: PropTypes.string,
  orderType: PropTypes.string.isRequired,
  restaurant: restaurantPropTypes,
  showDeliveryAddressFinder: PropTypes.func.isRequired,
  showRestaurantFinder: PropTypes.func,
};

LocationLink.defaultProps = {
  deliveryAddress: null,
  deliveryNotes: null,
  restaurant: null,
  showRestaurantFinder: undefined,
};

const ComposedLocationLink = observer(LocationLink);
export { ComposedLocationLink as LocationLink };
