import { Formik } from 'formik';
import { PropTypes } from 'prop-types';
import { schemas } from '@nandosaus/constants';
import React from 'react';
import styled from 'styled-components';

import { Alert } from '../../alert';
import { Box, Flex } from '../../grid';
import { Button } from '../../button';
import { FormikTextInput } from '../../input';
import { Icon } from '../../icons';
import { Modal } from '../../modal';
import { P } from '../../typography';

const ValidateStreetNumber = ({ saveStreetNumber, handleBack, handleClose, address, streetNumber }) => (
  <Formik initialValues={{ streetNumber }} onSubmit={saveStreetNumber} validationSchema={schemas.streetNumber}>
    {({ handleSubmit, isSubmitting }) => (
      <Modal
        handleBack={handleBack}
        handleClose={handleClose}
        modalTitle="Confirm delivery details"
        open
        buttons={{
          primary: (
            <StyledButton type="submit" onClick={handleSubmit} isLoading={isSubmitting || undefined}>
              Confirm address
            </StyledButton>
          ),
        }}
      >
        <Box px="1.5rem" py="0.5rem" minHeight={{ md: 494 }}>
          <P mt={1} variant={3} color="greyPrimary">
            Delivery address
          </P>
          <Flex mt="0.5rem" mb={1}>
            <Icon name="locationMarker" fill="black" />
            <TruncateText ml="0.5rem" color="black" fontWeight="bold">
              {address}
            </TruncateText>
          </Flex>
          <FormikTextInput name="streetNumber" label="Street Number" autoFocus />
          {!!streetNumber && (
            <Box mt={1}>
              <Alert type="info">
                We had a problem locating your exact address. Please confirm your street address number
              </Alert>
            </Box>
          )}
        </Box>
      </Modal>
    )}
  </Formik>
);

const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: auto;
  }
`;

const TruncateText = styled(P)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

ValidateStreetNumber.propTypes = {
  saveStreetNumber: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  streetNumber: PropTypes.string,
};

ValidateStreetNumber.defaultProps = {
  streetNumber: '',
};

export { ValidateStreetNumber };
