import { DATE_TIME_FORMATS, ORDER_TYPES, SCHEDULE_TYPES } from '@nandosaus/constants';
import { get, isDate } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import React from 'react';

import { Box, Flex } from '../../grid';
import { Icon } from '../../icons';
import { Label, P, Subheading } from '../../typography';
import { OrderTimePicker } from '../order-time-picker';
import { useFeature } from '../../../hooks/use-feature';

dayjs.extend(isSameOrBefore);

const ScheduleDelivery = () => {
  const { DeliveryStore } = useRootStore();
  const showScheduling = useFeature('delivery-scheduling');

  const {
    formattedDeliveryTime,
    earliestDeliverySlot,
    lastDeliverySlot,
    preferredDeliveryTime,
    setPreferredDeliveryTime,
  } = DeliveryStore;
  const minTime = dayjs(earliestDeliverySlot);
  const maxTime = dayjs(lastDeliverySlot);

  const availablePickupTimes = [
    {
      text: `As soon as possible ()`,
      value: SCHEDULE_TYPES.ASAP,
      time: SCHEDULE_TYPES.ASAP,
    },
  ];

  for (let startTime = minTime; startTime.isSameOrBefore(maxTime); startTime = startTime.add(15, 'minutes')) {
    availablePickupTimes.push({
      value: startTime.format(DATE_TIME_FORMATS.TIME_DEFAULT),
      text: startTime.format(DATE_TIME_FORMATS.TIME_DEFAULT),
      // Additional time value required, as Input needs to match the value to text
      time: startTime.toDate(),
    });
  }

  const onChange = selectedTime => {
    const { time } = availablePickupTimes.find(({ value }) => value === selectedTime);

    if (time === SCHEDULE_TYPES.ASAP) {
      setPreferredDeliveryTime(SCHEDULE_TYPES.ASAP);
      return;
    }

    setPreferredDeliveryTime(time.toDate());
  };

  const onFocusChange = event => {
    const selectedTime = event.target.value;
    onChange(selectedTime);
  };

  const initialTime =
    preferredDeliveryTime === ORDER_TYPES.ASAP ? get(availablePickupTimes, '[0].text', '') : preferredDeliveryTime;

  const defaultValue = isDate(preferredDeliveryTime) ? initialTime : SCHEDULE_TYPES.ASAP;

  const deliveryTime = formattedDeliveryTime;

  return (
    <>
      <Label as="label" mb="1rem" fontWeight="normal" color="greyPrimary" htmlFor="deliveryTimeChange">
        When
      </Label>
      {!showScheduling ? (
        <Flex justifyContent="center" alignItems="center">
          <Icon mr={1} name="clock" />
          <Box flex={1}>
            <Subheading variant={3}>As soon as possible</Subheading>
            <P variant={3} mt="0.25rem">
              {deliveryTime ? `Estimated delivery in ${deliveryTime}` : null}
            </P>
          </Box>
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Icon mr={1} name="clock" />
          <OrderTimePicker
            defaultValue={defaultValue}
            errorMessage="Please select a valid delivery time"
            name="deliveryTimeChange"
            onChange={onChange}
            onFocus={onFocusChange}
            options={availablePickupTimes}
            placeholder="Select A Delivery Time"
          />
        </Flex>
      )}
    </>
  );
};

const ObservedScheduleDelivery = observer(ScheduleDelivery);
export { ObservedScheduleDelivery as ScheduleDelivery };
