import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { P } from '@components/typography';
import { breakpoints, colors } from '../../../constants';
import { Flex } from '../../grid';
import { Icon } from '../../icons';

const CartButton = ({ onClick, quantity }) => {
  const quantityLabel = quantity < 100 ? quantity : '>99';
  const hasQuantity = quantity > 0;

  return (
    <Button onClick={onClick} data-testid="cart-button" aria-label="Cart">
      <Flex alignItems="center" px="0.5rem" py="0.25rem">
        <Icon name="shoppingBag" width="24px" height="24px" mr={hasQuantity ? '0.25rem' : '0'} />
        <P fontWeight="bold" variant={1}>
          {hasQuantity && quantityLabel}
        </P>
      </Flex>
    </Button>
  );
};

const Button = styled.button`
  border-radius: 4px;

  ${({ theme }) => `
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
    fill: ${theme.colors.white};
  `};

  &:hover {
    color: ${colors.neonTeal400};
    fill: ${colors.neonTeal400};
  }

  @media (min-width: ${breakpoints.xl}) {
    font-size: 20px;
  }
`;

CartButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
};

export { CartButton };
