import { isNil } from 'lodash';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../../grid';
import { Icon } from '../../icons';
import { Label } from '../../typography';

const OrderSettingsOption = ({ iconName, label, children, onClick }) => (
  <Flex flexDirection="column" flex={1}>
    <Label my="0.5rem" fontWeight="normal" color="greyPrimary">
      {label}
    </Label>
    {!isNil(onClick) ? (
      <StyledItem onClick={onClick}>
        {iconName && (
          <Box mr={1}>
            <Icon name={iconName} />
          </Box>
        )}
        {children}
      </StyledItem>
    ) : (
      <Flex my="0.5rem">
        {iconName && (
          <Box mr={1}>
            <Icon name={iconName} />
          </Box>
        )}
        {children}
      </Flex>
    )}
  </Flex>
);

const StyledItem = styled.button`
  display: flex;
  text-align: left;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;

  :hover {
    background-color: ${({ theme }) => theme.colors.grey50};
  }
`;

OrderSettingsOption.propTypes = {
  iconName: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

OrderSettingsOption.defaultProps = {
  iconName: null,
  onClick: undefined,
};

export { OrderSettingsOption };
