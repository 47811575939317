import { UPSELL_ITEM_MAX_QUANTITY } from '@nandosaus/constants';

import { observer } from 'mobx-react';
import { productPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';

import { Box, Flex } from '../grid';
import { P } from '../typography';
import { Tags } from '../tags';
import { TextClamp } from '../text-clamp';
import { Card } from './card';
import { TextPill } from '../text-pill';
import { QuantityIndicator } from '../quantity-indicator';
import { IconButton } from '../icon-button';

const UpsellItemCard = ({ item, isNew, addItem, removeItem, isItemInCart, isDiscountApplied, quantitySelected }) => {
  const itemPrice = item?.prices.formattedPrice;
  const itemKilojoules = item?.formattedKilojoules;
  const isEligibleForDeliveryDiscount = item?.isEligibleForDeliveryDiscount;

  return (
    <Flex
      position="relative"
      border="1px solid transparent"
      height="100%"
      minHeight="100px"
      minWidth="150px"
      data-item-id={item.id}
    >
      {isNew && (
        <TextPill
          backgroundColor="neonPink"
          width="50px"
          color="white"
          position="absolute"
          top={{ _: 1, lg: '0.5rem' }}
          left={{ _: 1, lg: '0.5rem' }}
          zIndex={1}
          text="NEW"
        />
      )}
      <QuantityIndicator
        quantity={quantitySelected}
        backgroundColor="black"
        top={{ _: 1, lg: '0.5rem' }}
        right={{ _: 1, lg: '0.5rem' }}
        zIndex={1}
      />
      <Card
        flexWrap={{ xs: 'no-wrap' }}
        flexDirection="column"
        contentWidth={1}
        backgroundColor="transparent"
        contentHeight="100%"
        isDisabled={!item.isAvailable}
        image={
          item.image
            ? {
                url: item.image,
                alt: item.imageAlt,
                aspectRatio: '16:27',
              }
            : null
        }
        justifyContent="space-between"
        padding={null}
        borderRadius={8}
        rounded
        isEligibleForDeliveryDiscount={isEligibleForDeliveryDiscount}
        isDiscountApplied={isDiscountApplied}
        deliveryDiscountSpacing={{ xs: '2.5rem', lg: 2, xxl: '1.5rem' }}
        isItemInCart={isItemInCart}
      >
        <Box position="relative" height="100%">
          <Flex justifyContent="center" mb={{ xs: 2, lg: '0.75rem' }}>
            <IconButton
              position="absolute"
              transform="translate(-70%, -50%)"
              iconName="minus"
              wrapperSize={{ xs: '44px', lg: '48px' }}
              disabled={quantitySelected === 0}
              onClick={removeItem}
            />
            <IconButton
              position="absolute"
              transform="translate(70%, -50%)"
              iconName="plus"
              wrapperSize={{ xs: '44px', lg: '48px' }}
              disabled={quantitySelected >= UPSELL_ITEM_MAX_QUANTITY}
              onClick={addItem}
            />
          </Flex>
          <Box justifyContent="flex-start" py={{ xs: '0.75rem', xxl: '0.75rem' }}>
            <P variant={2} fontWeight="bold" mb="0.5rem" color={!item.isAvailable ? 'grey600' : undefined}>
              <TextClamp>{item.groupName || item.name}</TextClamp>
            </P>
            <Box mt={{ md: 'auto' }}>
              {item.hasVariablePrice && (
                <P variant={4} mb={{ xs: '.125rem', md: '.25rem' }} mt={0.5} color="greyPrimary">
                  From
                </P>
              )}
              <Flex flexDirection={{ xs: 'column', md: 'row' }} mb={0.5} justifyContent="space-between">
                <Flex>
                  {itemPrice && (
                    <P variant={3} mr=".5rem" color={!item.isAvailable ? 'greyPrimary' : undefined}>
                      {itemPrice}
                    </P>
                  )}
                  {itemKilojoules && (
                    <P color="greyPrimary" variant={3}>
                      {itemKilojoules}
                    </P>
                  )}
                </Flex>

                {item.formattedDietaryPreferencesList && (
                  <Box>
                    <Tags labels={item.formattedDietaryPreferencesList} size="small" />
                  </Box>
                )}
              </Flex>
            </Box>
          </Box>
        </Box>
      </Card>
    </Flex>
  );
};

UpsellItemCard.propTypes = {
  item: productPropTypes.isRequired,
  isNew: PropTypes.bool,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  isItemInCart: PropTypes.bool,
  isDiscountApplied: PropTypes.bool,
  quantitySelected: PropTypes.number,
};

UpsellItemCard.defaultProps = {
  isNew: false,
  isItemInCart: false,
  isDiscountApplied: false,
  quantitySelected: 0,
};

const ComposedUpsellItemCard = observer(UpsellItemCard);
export { ComposedUpsellItemCard as UpsellItemCard };
