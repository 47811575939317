import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

import { P } from '@components/typography';
import { Box } from '../grid';

const MotionBox = motion(Box);

export const QuantityIndicator = ({ quantity, backgroundColor, bottom, top, right, left, zIndex, position }) => {
  if (quantity <= 0) {
    return <AnimatePresence initial={false} />;
  }
  return (
    <AnimatePresence initial={false}>
      <MotionBox
        as="span"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={backgroundColor}
        borderRadius="50%"
        width="24px"
        height="24px"
        position={position}
        bottom={bottom}
        top={top}
        right={right}
        left={left}
        initial={{
          opacity: 0,
          scale: 0,
        }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: {
            duration: 0.2,
          },
        }}
        exit={{
          opacity: 0,
          scale: 0,
          transition: {
            duration: 0.1,
          },
        }}
        zIndex={zIndex}
        transition={{
          default: { ease: 'linear' },
        }}
      >
        <Box as="span" width={{ _: '14px', lg: '18px' }} color="white">
          <P variant={4} textAlign="center" fontWeight="bold">
            {quantity}
          </P>
        </Box>
      </MotionBox>
    </AnimatePresence>
  );
};

QuantityIndicator.propTypes = {
  quantity: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
  bottom: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  zIndex: PropTypes.number,
  position: PropTypes.string,
};

QuantityIndicator.defaultProps = {
  backgroundColor: 'success600',
  bottom: '-0.5rem',
  top: undefined,
  right: '0.5rem',
  left: undefined,
  zIndex: undefined,
  position: 'absolute',
};
