import { compact, values } from 'lodash';
import { ORDER_TYPES } from '@nandosaus/constants';
import { PropTypes } from 'prop-types';
import React from 'react';

import { Alert } from '../../alert';
import { Box } from '../../grid';
import { InputList } from '../../input-list';
import { Label } from '../../typography';
import { useFeature } from '../../../hooks/use-feature';

const OrderTypePicker = ({ errorMessage, orderType, handleOrderType, availableOrderTypes }) => {
  const showDineIn = useFeature('dine-in');
  const showDelivery = useFeature('delivery');

  const orderTypes = compact([
    {
      label: 'Pick up',
      value: ORDER_TYPES.PICK_UP,
      icon: 'shoppingBag',
      disabled: false,
    },
    showDelivery
      ? {
          label: 'Delivery',
          value: ORDER_TYPES.DELIVERY,
          icon: 'delivery',
          disabled: false,
        }
      : null,
    showDineIn
      ? {
          label: 'Dine in',
          value: ORDER_TYPES.DINE_IN,
          icon: 'cutlery',
          disabled: false,
        }
      : null,
  ]).map(option => {
    const disabled = !availableOrderTypes.includes(option.value);
    return { ...option, disabled };
  });

  return (
    <Box mb={1}>
      <Label mb="0.5rem" fontWeight="normal" color="greyPrimary">
        Order Type
      </Label>
      <InputList items={orderTypes} onSelect={handleOrderType} selectedItems={[orderType]} type="tile" />
      {errorMessage !== undefined && (
        <Box mt="0.5rem">
          <Alert type="error">{errorMessage}</Alert>
        </Box>
      )}
    </Box>
  );
};

OrderTypePicker.propTypes = {
  errorMessage: PropTypes.string,
  handleOrderType: PropTypes.func.isRequired,
  orderType: PropTypes.oneOf(values(ORDER_TYPES)),
  availableOrderTypes: PropTypes.arrayOf(PropTypes.oneOf(values(ORDER_TYPES))),
};

OrderTypePicker.defaultProps = {
  errorMessage: undefined,
  orderType: undefined,
  availableOrderTypes: [],
};

export { OrderTypePicker };
