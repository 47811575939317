import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';
import { Children, useRef } from 'react';
import { useTrackImpression } from '@hooks/use-track-impression';
import { Flex } from '../../grid';

const ScrollingSnappingRow = styled(Flex)`
  display: flex;
  flex-direction: row;
  scroll-snap-type: x proximity;
  height: ${({ height }) => height};
`;

const OrderAtTableCarousel = ({ children, carouselHeight, impressionEvent, gap, width, px, pb }) => {
  const count = Children.count(children);
  const impressionRef = useRef(null);
  useTrackImpression({ ref: impressionRef, eventName: impressionEvent });

  const ariaProps = count > 0 ? { role: 'menu', 'aria-label': 'menu carousel' } : {};

  return (
    <ScrollingSnappingRow
      {...ariaProps}
      ref={impressionRef}
      height={carouselHeight}
      overflow="auto"
      touchAction="pan-x"
      gap={gap}
      width={width}
      px={px}
      pb={pb}
    >
      {children}
    </ScrollingSnappingRow>
  );
};

OrderAtTableCarousel.propTypes = {
  children: PropTypes.any,
  carouselHeight: StyledSystemPropTypes.layout.height,
  impressionEvent: PropTypes.string,
  width: StyledSystemPropTypes.layout.width,
  gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  px: StyledSystemPropTypes.space.px,
  pb: StyledSystemPropTypes.space.pb,
};

OrderAtTableCarousel.defaultProps = {
  children: null,
  carouselHeight: 'fit',
  impressionEvent: undefined,
  width: '100%',
  gap: 1,
  px: 0,
  pb: 0,
};

const ComposedOrderAtTableCarousel = observer(OrderAtTableCarousel);
export { ComposedOrderAtTableCarousel as OrderAtTableCarousel };
