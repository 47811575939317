import { clone, recordPatches } from 'mobx-state-tree';
import { DELIVERABILITY_STATUS } from '@nandosaus/constants';
import { get, isNil } from 'lodash';
import { observer, useLocalStore } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { useRef, useState } from 'react';
import { useRootStore } from '@nandosaus/state-management';
import styled from 'styled-components';

import { A, Label, P, Subheading } from '../typography';
import { AddressAutocomplete } from '../google-places-autocomplete';
import { Box, Flex } from '../grid';
import { breakpoints } from '../../constants';
import { Button } from '../button';
import { DeliveryError } from './delivery-error';
import { DeliveryEstimate } from './delivery-estimate';
import { Icon } from '../icons';
import { LoadingIndicator } from '../icons/loading-indicator';
import { Modal } from '../modal';
import { TextInput } from '../input';
import { useFeature } from '../../hooks/use-feature';
import { ValidateStreetNumber } from './validate-street-number';

const MODAL_SCREENS = {
  DEFAULT: 'addressSearch',
  ADDRESS_CONFIRMATION: 'addressConfirm',
  STREET_NUMBER_CONFIRMATION: 'streetNumberConfirm',
};

const DeliveryAddressModal = ({ onCancel, onClose, onConfirm }) => {
  const [modalScreen, setModalScreen] = useState(MODAL_SCREENS.DEFAULT);
  const [customUnit, setCustomUnit] = useState('');
  const customStreetNumber = useRef('');
  const { MemberStore } = useRootStore();

  const RootStore = useRootStore();

  // Cloning the store allows us to work on it locally and calculate delivery
  // estimates without needing to commit changes. Changes are then committed
  // when the user presses the confirm button.
  const localStore = useLocalStore(() => clone(RootStore));
  const patchRecorder = useRef(recordPatches(localStore));

  const { DeliveryStore, CartStore, OrderingContextStore } = localStore;

  const {
    deliverabilityStatus,
    deliveryAddress,
    deliveryNotes,
    getDeliveryEstimate,
    previousSearches,
    setDeliveryAddress,
    setDeliveryStreet,
    setDeliveryNotes,
    setDeliveryUnit,
    shortestDeliveryTime,
  } = DeliveryStore;

  const loading = DeliveryStore.loading || CartStore.loading;

  const savedAddressEnabled = useFeature('save-order-addresses-and-restaurants');

  const savedAddresses = savedAddressEnabled ? get(MemberStore, 'profile.addresses', []) : previousSearches;

  const setCustomStreetNumber = streetNumber => {
    customStreetNumber.current = streetNumber;
  };

  const saveChanges = () => {
    if (deliveryAddress.unit !== customUnit) {
      setDeliveryUnit(customUnit);
    }

    patchRecorder.current.replay(RootStore);

    setModalScreen(MODAL_SCREENS.DEFAULT);
    onConfirm();
  };

  const saveStreetNumber = ({ streetNumber }) => {
    setDeliveryStreet(`${streetNumber} ${deliveryAddress.street}`);
    OrderingContextStore.updateSettings({ showMap: false });
    setModalScreen(MODAL_SCREENS.ADDRESS_CONFIRMATION);
  };

  const handleAddressSelection = ({
    address: { latitude, longitude, street, suburb, state, postcode, unit },
    saveToPreviousSearch,
  }) => {
    setDeliveryAddress(
      {
        street,
        suburb,
        postcode,
        state,
        longitude,
        latitude,
        unit,
      },
      { saveToPreviousSearch }
    );

    if (!isNil(unit)) {
      setCustomUnit(unit);
    }
  };

  if (modalScreen === MODAL_SCREENS.DEFAULT) {
    return (
      <Modal handleBack={onCancel} handleClose={onClose} modalTitle="Delivery address" open webHeight="800px">
        <Box px="1.5rem" py="0.5rem" minHeight={{ md: 520 }}>
          <AddressAutocomplete
            countryCode={process.env.locationAutocomplete.countryCode}
            handleAddressSelection={handleAddressSelection}
            predefinedPlaces={savedAddresses.map(previousSearch => ({
              description: previousSearch.formattedAddress,
              address: previousSearch,
            }))}
            handleConfirmStreetAddress={streetNumber => {
              setCustomStreetNumber(streetNumber);
              setModalScreen(MODAL_SCREENS.STREET_NUMBER_CONFIRMATION);
            }}
            showConfirmationModal={() => setModalScreen(MODAL_SCREENS.ADDRESS_CONFIRMATION)}
          />
        </Box>
      </Modal>
    );
  }

  const validDeliveryLocation = deliverabilityStatus === DELIVERABILITY_STATUS.DELIVERABLE;

  if (modalScreen === MODAL_SCREENS.STREET_NUMBER_CONFIRMATION && validDeliveryLocation) {
    return (
      <ValidateStreetNumber
        saveStreetNumber={saveStreetNumber}
        handleBack={() => setModalScreen(MODAL_SCREENS.DEFAULT)}
        handleClose={onClose}
        address={deliveryAddress.formattedAddress}
        streetNumber={customStreetNumber.current}
      />
    );
  }

  const reselectAddress = () => {
    setModalScreen(MODAL_SCREENS.DEFAULT);
    setCustomUnit('');
  };

  return (
    <Modal
      handleBack={reselectAddress}
      handleClose={onClose}
      modalTitle="Delivery address"
      open
      buttons={{
        primary: (
          <StyledButton disabled={loading || shortestDeliveryTime === null} onClick={saveChanges}>
            Save and continue
          </StyledButton>
        ),
      }}
      webHeight="800px"
    >
      <Flex flexDirection="column" px="1.5rem" pt={1} minHeight={{ md: 520 }} height="100%">
        <Flex alignItems="center" minWidth={0} mb={1}>
          <Box>
            <Icon name="locationMarker" fill="black" />
          </Box>
          <TruncateText ml={1}>
            <P as="span" color="black">
              {deliveryAddress.formattedStreet}
            </P>
            <P as="span" color="greyPrimary">
              , {deliveryAddress.formattedSuburbStatePostcode}
            </P>
          </TruncateText>

          <Flex justifyContent="flex-end">
            <A variant={2} onClick={reselectAddress} ml={1}>
              Change
            </A>
          </Flex>
        </Flex>
        {loading && (
          <Flex flex={1} flexDirection="column" alignItems="center" justifyContent="center">
            <LoadingIndicator />
            {DeliveryStore.loading && (
              <P variant={3} fontWeight="bold" textAlign="center" mt="0.5rem">
                Finding your closest restaurant
              </P>
            )}
            {CartStore.loading && (
              <P variant={3} fontWeight="bold" textAlign="center" mt="0.5rem">
                Validating
              </P>
            )}
          </Flex>
        )}
        {!loading && !validDeliveryLocation && (
          <DeliveryError
            onChangeAddressClick={reselectAddress}
            onChangeOrderClick={onCancel}
            onRetryClick={getDeliveryEstimate}
            status={deliverabilityStatus}
          />
        )}
        {!loading && validDeliveryLocation && (
          <>
            <Box mb={{ xs: 2, md: '1.5rem' }}>
              <Flex justifyContent="space-between" mb="0.2rem">
                <Label fontWeight="normal" color="greyPrimary">
                  Unit / Flat / Building Etc.
                </Label>
                <P color="grey300" variant={3}>
                  optional
                </P>
              </Flex>
              <TextInput name="unit" value={customUnit} onChange={event => setCustomUnit(event.target.value)} />
            </Box>
            <Box mb={1}>
              <DeliveryEstimate handleChangeOrderType={onCancel} estimate={shortestDeliveryTime} />
            </Box>
            <Box mb={{ xs: 2, md: '1.5rem' }}>
              <Flex justifyContent="space-between" mb="0.2rem">
                <Label fontWeight="normal" color="greyPrimary">
                  Delivery instructions
                </Label>
                <P color="grey300" variant={3}>
                  optional
                </P>
              </Flex>
              <TextInput
                name="notes"
                value={deliveryNotes || ''}
                onChange={event => setDeliveryNotes(event.target.value)}
                placeholder="Add instructions to help with delivery"
              />
            </Box>
            <Subheading variant={3} mb="0.5rem">
              Good news.
            </Subheading>
            <P variant={3} mb={2}>
              You can now earn and burn points on delivery orders plus redeem all your favourite Nando’s offers through
              our delivery channel.
            </P>
          </>
        )}
      </Flex>
    </Modal>
  );
};

const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    width: auto;
  }
`;

const TruncateText = styled(P)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

DeliveryAddressModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const ComposedDeliveryAddressModal = observer(DeliveryAddressModal);
export { ComposedDeliveryAddressModal as DeliveryAddressModal };
