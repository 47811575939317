import dayjs from 'dayjs';

export const validatePickupTime = time => {
  if (time === '' || time === 'ASAP') {
    return true;
  }

  const difference = dayjs(time).diff(dayjs(), 'minutes');

  if (difference >= 14) {
    return true;
  }

  return false;
};
