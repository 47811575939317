import { MAX_LOCATION_DISTANCE } from '@nandosaus/constants';
import React from 'react';

import { DefaultState } from '../../../default';

const NoRestaurantResults = () => (
  <DefaultState
    subtitle={`Unfortunately we don’t have a restaurant within ${MAX_LOCATION_DISTANCE / 1000}km of your search.`}
    title="No results"
  />
);

export { NoRestaurantResults };
