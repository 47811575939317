import { DEFAULT_PICKUP_TIMES, ORDER_TYPES, SCHEDULE_TYPES } from '@nandosaus/constants';
import { first, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import React, { useState } from 'react';

import { Box, Flex } from '../../grid';
import { Icon } from '../../icons';
import { Label, P } from '../../typography';
import { OrderTimePicker } from '../order-time-picker';
import { validatePickupTime } from '../../../utils/validate';

const SchedulePickUp = observer(({ restaurant }) => {
  const [isTimeValid, setIsTimeValid] = useState(true);
  const { OrderingContextStore } = useRootStore();
  const { isPickUp, fulfilment } = OrderingContextStore;

  // Use defaults if settings haven't been commited to the store yet
  const { schedule, time, orderEstimateFormatted, isAsap } = (isPickUp && fulfilment) || {
    schedule: SCHEDULE_TYPES.ASAP,
    time: undefined,
    isAsap: true,
  };

  const availablePickupTimes = restaurant ? restaurant.availablePickupTimes({ orderType: ORDER_TYPES.PICK_UP }) : [];

  // Used in the event the pickup times are marked as 'Closed'
  const defaultTime = isEmpty(availablePickupTimes)
    ? DEFAULT_PICKUP_TIMES.OPENING_TIME
    : first(availablePickupTimes).value;

  const onChange = selectedTime => {
    const isValidTime = validatePickupTime(selectedTime);
    setIsTimeValid(isValidTime);

    if (!isValidTime) {
      return;
    }

    // @cleanup: these shouldn't be commited to the store here, should happen with all the other settings in OrderSettingsScreen
    // Previously we could set values without setting the order type so could get away with it
    if (selectedTime === SCHEDULE_TYPES.ASAP) {
      OrderingContextStore.updateSettings({
        orderType: ORDER_TYPES.PICK_UP,
        schedule: SCHEDULE_TYPES.ASAP,
        time: undefined,
      });

      return;
    }

    OrderingContextStore.updateSettings({
      orderType: ORDER_TYPES.PICK_UP,
      schedule: SCHEDULE_TYPES.SCHEDULED,
      time: isValidTime ? selectedTime : undefined,
    });
  };

  const onFocusChange = event => {
    const selectedTime = event.target.value;
    onChange(selectedTime);
  };

  const formattedStatusMessage = restaurant?.getFormattedStatusMessage({ orderType: ORDER_TYPES.PICK_UP });
  const disabled = formattedStatusMessage?.disableOptions || false;

  const enableScheduling = !isEmpty(availablePickupTimes);

  const initialTime = isAsap ? defaultTime : time;

  const defaultValue = schedule === SCHEDULE_TYPES.ASAP ? SCHEDULE_TYPES.ASAP : initialTime;

  if (disabled) {
    return null;
  }

  return (
    <>
      <Label as="label" mb="1rem" fontWeight="normal" color="greyPrimary" htmlFor="pickupTime">
        When
      </Label>
      {!enableScheduling ? (
        <Flex justifyContent="center" alignItems="center">
          <Icon mr={1} name="clock" />
          <Box flex={1}>
            <P variant={3} fontWeight="bold">
              As soon as possible
            </P>
            <P variant={3} mt="0.25rem">
              {orderEstimateFormatted}
            </P>
          </Box>
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Box>
            <Icon mr={1} name="clock" />
          </Box>
          <OrderTimePicker
            defaultValue={defaultValue}
            errorMessage="Please enter a valid pickup time"
            isError={!isTimeValid}
            name="pickupTime"
            onChange={onChange}
            onFocus={onFocusChange}
            options={[
              {
                text: `As soon as possible`,
                value: SCHEDULE_TYPES.ASAP,
              },
              ...availablePickupTimes,
            ]}
            placeholder="Select A Pickup Time"
          />
        </Flex>
      )}
    </>
  );
});

export { SchedulePickUp };
