import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { DefaultState } from '../../default';
import { Flex } from '../../grid';

const EmptyMap = ({ height }) => (
  <EmptyMapContainer p={1} height={height} alignItems="center" justifyContent="center">
    <Flex width="60%">
      <DefaultState title="Enter your location to view nearby restaurants" />
    </Flex>
  </EmptyMapContainer>
);

const EmptyMapContainer = styled(Flex)`
  background-image: ${`url(${'/static/images/menu/map_background.jpg'})`};
  background-position: center;
  background-size: cover;
`;

EmptyMap.propTypes = {
  height: PropTypes.string.isRequired,
};

export { EmptyMap };
