import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import { HeadingWithLineHighlights } from '@components/heading-with-line-highlights';
import { Box } from '@components/grid';
import { Button } from '@components/button';
import { P } from '@components/typography';
import { Modal } from '@components/modal';

const TabOrderLeaveModal = ({ modalOpen, handleModalClose, handleLeaveGroup }) => {
  return (
    <Modal
      modalTitle=""
      handleClose={handleModalClose}
      open={modalOpen}
      isDividerHidden
      backgroundImage="/static/images/background.svg"
      headerBackgroundColor="transparent"
      headerButtonColor="black"
      showShadow
      buttons={{
        primary: (
          <Button isFullWidth variant="secondary" onClick={handleModalClose}>
            Stay
          </Button>
        ),
        secondary: (
          <Button isFullWidth variant="primary" onClick={handleLeaveGroup}>
            Leave Group
          </Button>
        ),
      }}
    >
      <Box position="relative" mb="2">
        <Box textAlign="center" position="relative" mb="2" mx="2">
          <HeadingWithLineHighlights
            heading="Leaving?"
            color="black"
            headingLevel="h2"
            highlightBackgroundColor="neonTeal400"
            overrideHeadingHighlighting={['highlightAllLines']}
          />
          <P variant={2}>If so, your order will not be added to the group.</P>
        </Box>
      </Box>
    </Modal>
  );
};

TabOrderLeaveModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleLeaveGroup: PropTypes.func.isRequired,
};

const ConnectedTabOrderLeaveModal = observer(TabOrderLeaveModal);

export { ConnectedTabOrderLeaveModal as TabOrderLeaveModal };
