import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { HeadingWithLineHighlights } from '@components/heading-with-line-highlights';
import { QRCode } from '@components/qr-code';
import { Box } from '@components/grid';
import { Button } from '@components/button';
import { P } from '@components/typography';
import { Modal } from '@components/modal';
import { ShareButton } from '@components/share-button';
import { CopyTextButton } from '@components/copy-text-button';
import { BUTTON_VARIANTS } from '@components/button/constants';
import { TabOrderLeaveModal } from '@components/tab-order-leave-modal';
import { ANALYTICS_EVENTS, colors } from '@nandosaus/constants';
import { useRootStore } from '@nandosaus/state-management';
import { useIsMinWidth } from '@hooks/use-is-min-width';
import { analytics } from '@analytics';

const TabOrderShareModal = ({ modalOpen, handleModalClose }) => {
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

  const { GroupOrderStore } = useRootStore();
  const { leaveGroup, canLeaveGroup, name, shareLink } = GroupOrderStore;

  const handleLeaveModalOnClose = () => {
    setIsLeaveModalOpen(false);
  };

  const handleLeaveModalOnLeave = async () => {
    await leaveGroup();
    setIsLeaveModalOpen(false);
    analytics.track(ANALYTICS_EVENTS.GROUP_ORDER_GROUP_LEFT, { name });
    handleModalClose();
  };

  const leaveGroupButton = (
    <Button isFullWidth variant={BUTTON_VARIANTS.LINK} color={colors.red600} onClick={() => setIsLeaveModalOpen(true)}>
      Leave Group
    </Button>
  );

  const isDesktop = useIsMinWidth('lg');
  return (
    <>
      <Modal
        handleClose={handleModalClose}
        open={modalOpen}
        isDividerHidden
        backgroundImage="/static/images/background.svg"
        headerBackgroundColor="transparent"
        headerButtonColor="black"
        showShadow={isDesktop}
        width={{ _: null, lg: '45%' }}
        buttons={{
          primary: (
            <Button isFullWidth variant="primary" onClick={handleModalClose}>
              View Menu
            </Button>
          ),
          secondary: !isDesktop && canLeaveGroup ? leaveGroupButton : null,
        }}
        childrenContainerStyle={{ marginBottom: '0rem' }}
        buttonContainerStyle={{ position: 'relative', backgroundColor: 'transparent' }}
      >
        <Box position="relative" height="100%" mb="2">
          <Box textAlign="center" position="relative" mb="2" mx="2">
            {!!name && (
              <HeadingWithLineHighlights
                heading={name}
                color="black"
                headingLevel="h2"
                highlightBackgroundColor="neonTeal400"
                overrideHeadingHighlighting={['highlightAllLines']}
              />
            )}
            <P mb={{ md: '1.5rem', _: '4rem' }} fontWeight="bold" variant={2}>
              Invite others to the group by scanning the QR code or sharing the link!
            </P>
            {!!shareLink && <QRCode url={shareLink} size={180} />}
          </Box>
          {!!shareLink && (
            <>
              <CopyTextButton
                onClick={() => analytics.track(ANALYTICS_EVENTS.GROUP_ORDER_GROUP_SHARED, { groupName: name })}
                text={shareLink}
                label="Group order link"
              />
              <ShareButton
                onClick={() => analytics.track(ANALYTICS_EVENTS.GROUP_ORDER_GROUP_SHARED, { groupName: name })}
                url={shareLink}
                buttonText="Share this order"
                title="Join my Nando’s Group Order"
              />
            </>
          )}
          {isDesktop && canLeaveGroup && <Box pt="2">{leaveGroupButton}</Box>}
        </Box>
      </Modal>
      <TabOrderLeaveModal
        modalOpen={isLeaveModalOpen}
        handleModalClose={handleLeaveModalOnClose}
        handleLeaveGroup={handleLeaveModalOnLeave}
      />
    </>
  );
};

TabOrderShareModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

const ConnectedTabOrderShareModal = observer(TabOrderShareModal);

export { ConnectedTabOrderShareModal as TabOrderShareModal };
