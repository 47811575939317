import { analytics } from '@analytics';
import { autorun } from 'mobx';
import { noop } from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';
import { OrderAtTableCarousel } from '@components/carousel/order-at-table-carousel';
import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { AddOfferModal } from '../../add-offer-modal';
import { Box, Flex } from '../../grid';
import { Button } from '../../button';
import { OfferModal } from '../offer-modal';
import { H2, H3, P } from '../../typography';
import { useFeature } from '../../../hooks/use-feature';
import { BrazePromotionCard, OfferPromotionCard, PromotionCard } from '../promotion-card';
import { PromotionCardSkeleton } from '../promotion-card-skeleton';

const LIST_TYPES = {
  SCROLL: 'default',
  CAROUSEL: 'carousel',
  VERTICAL: 'vertical',
};

const PROMOTION_CARD_SKELETON_ASPECT_RATIO = '300/184';

const buildTrackingPayloadPromo = ({ promo, location }) => ({
  name: promo?.header,
  description: promo?.description,
  location,
});

const buildTrackingPayloadOffer = ({ offer, location }) => ({
  name: offer?.name,
  description: offer?.description,
  location,
});

const PromotionList = ({ customOpenModal, onlineOffersOnly, isCheckoutOfferVariant, analyticsLocation }) => {
  const [offerModalId, setOfferModalId] = useState(null);
  const [isAddOfferModalOpen, setIsAddOfferModalOpen] = useState(false);
  const { OfferStore, CartStore, MemberStore, BrazeStore } = useRootStore();

  const loading = OfferStore.loading || MemberStore.loading;

  useEffect(() => {
    const disposer = autorun(() => {
      if (MemberStore.isSignedIn) {
        OfferStore.loadOffers();
        OfferStore.loadActivatedPromoCodes();
      }
    });
    return () => {
      disposer();
    };
  }, [MemberStore, OfferStore]);

  const { onlineOffers, allOffers } = OfferStore;
  const appliedOfferId = CartStore.offerId;

  const visibleOffers = onlineOffersOnly ? onlineOffers : allOffers;

  const showPromoCode = useFeature('promo-codes');

  const handleChange = offer => {
    const isApplied = offer.id === appliedOfferId;

    if (isApplied) {
      CartStore.removeOffer();
    } else {
      CartStore.updateOffer(offer);
      analytics.track(
        ANALYTICS_EVENTS.OFFER_APPLIED,
        buildTrackingPayloadOffer({ offer, location: analyticsLocation })
      );
    }
  };

  const handleClickPromoCard = promo => {
    analytics.track(ANALYTICS_EVENTS.PROMO_CLICKED, buildTrackingPayloadPromo({ promo, location: analyticsLocation }));
  };

  const handleOpenModal = offer => {
    analytics.track(ANALYTICS_EVENTS.OFFER_CLICKED, buildTrackingPayloadOffer({ offer, location: analyticsLocation }));
    analytics.track(ANALYTICS_EVENTS.OFFER_VIEWED, buildTrackingPayloadOffer({ offer, location: analyticsLocation }));

    if (customOpenModal) {
      return customOpenModal(offer);
    }
    return setOfferModalId(offer.id);
  };

  const addOfferModal = (
    <AddOfferModal
      {...{
        open: isAddOfferModalOpen,
        handleClose: () => {
          setIsAddOfferModalOpen();
        },
        openOfferModal: handleOpenModal,
        onOfferApply: offer =>
          analytics.track(
            ANALYTICS_EVENTS.OFFER_APPLIED,
            buildTrackingPayloadOffer({ offer, location: analyticsLocation })
          ),
      }}
    />
  );

  const addPromoCodeLink = showPromoCode && (
    <Box>
      <Button variant="link" onClick={() => setIsAddOfferModalOpen(true)}>
        <P textTransform="none">Add promo code</P>
      </Button>
    </Box>
  );

  const buildOfferModalForOffer = offer =>
    offerModalId === offer.id &&
    !customOpenModal && (
      <OfferModal
        key={`modal-${offer.id}`}
        offer={offer}
        isApplied={offer.id === appliedOfferId}
        modalOpen={!!offerModalId}
        handleModalClose={() => {
          setOfferModalId(null);
          setIsAddOfferModalOpen(false);
        }}
        handleBack={isAddOfferModalOpen ? () => setOfferModalId(null) : null}
        onOfferChange={() => handleChange(offer)}
      />
    );

  const launchDarklyPromotions = useFeature('order-at-table-promotions');
  const brazePromotions = BrazeStore.getContentCardsByTypeAndFeatureFlags('order-at-table-promotions');

  const promos = useMemo(() => {
    if (isCheckoutOfferVariant) {
      return visibleOffers;
    }

    return [...visibleOffers, ...(brazePromotions || []), ...(launchDarklyPromotions || [])];
  }, [isCheckoutOfferVariant, visibleOffers, brazePromotions, launchDarklyPromotions]);

  return (
    <Box width="100%">
      {addOfferModal}
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={isCheckoutOfferVariant ? undefined : '1rem'}
        mx={isCheckoutOfferVariant ? '1rem' : 0}
      >
        {isCheckoutOfferVariant ? <H3 hideTramlines>Offers</H3> : <H2 hideTramlines>Promotions</H2>}
        <Box>{addPromoCodeLink}</Box>
      </Flex>
      <OrderAtTableCarousel
        impressionEvent={ANALYTICS_EVENTS.WELCOME_PAGE_PROMOTIONS_SEEN}
        gap="1rem"
        px={isCheckoutOfferVariant ? '1rem' : 0}
        mb="1rem"
      >
        {loading ? (
          <>
            <PromotionCardSkeleton role="menuitem" aspectRatio={PROMOTION_CARD_SKELETON_ASPECT_RATIO} />
            <PromotionCardSkeleton role="menuitem" aspectRatio={PROMOTION_CARD_SKELETON_ASPECT_RATIO} />
            <PromotionCardSkeleton role="menuitem" aspectRatio={PROMOTION_CARD_SKELETON_ASPECT_RATIO} />
            <PromotionCardSkeleton role="menuitem" aspectRatio={PROMOTION_CARD_SKELETON_ASPECT_RATIO} />
            <PromotionCardSkeleton role="menuitem" aspectRatio={PROMOTION_CARD_SKELETON_ASPECT_RATIO} />
          </>
        ) : (
          promos?.map(promo => {
            const key = promo?.id || promo?.header;

            if (promo?.extras) {
              return <BrazePromotionCard key={key} role="menuitem" brazeData={promo.extras} />;
            }

            if (promo?.id) {
              return (
                <Box key={key}>
                  <OfferPromotionCard
                    role="menuitem"
                    offer={promo}
                    isApplied={promo.id === appliedOfferId}
                    onClick={() => handleOpenModal(promo)}
                  />
                  {buildOfferModalForOffer(promo)}
                </Box>
              );
            }

            return <PromotionCard key={key} role="menuitem" {...promo} onClick={() => handleClickPromoCard(promo)} />;
          })
        )}
      </OrderAtTableCarousel>
    </Box>
  );
};

PromotionList.propTypes = {
  type: PropTypes.oneOf([LIST_TYPES.CAROUSEL, LIST_TYPES.SCROLL, LIST_TYPES.VERTICAL]),
  wasOfferUnlocked: PropTypes.bool,
  onOfferClick: PropTypes.func,
  onOfferApply: PropTypes.func,
  onOfferView: PropTypes.func,
  onOfferChange: PropTypes.func,
  customOpenModal: PropTypes.func,
  onlineOffersOnly: PropTypes.bool,
  isCheckoutOfferVariant: PropTypes.bool,
  analyticsLocation: PropTypes.string,
};

PromotionList.defaultProps = {
  type: LIST_TYPES.SCROLL,
  wasOfferUnlocked: null,
  onOfferClick: noop,
  onOfferApply: noop,
  onOfferView: noop,
  onOfferChange: noop,
  customOpenModal: null,
  onlineOffersOnly: false,
  isCheckoutOfferVariant: false,
  analyticsLocation: null,
};

const ObservedPromotionList = observer(PromotionList);
export { ObservedPromotionList as PromotionList };
