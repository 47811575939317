import { useRootStore } from '@nandosaus/state-management';
import { uniqBy } from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { MenuItemCardSkeleton } from '@components/card/menu-item-card-skeleton';
import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '@analytics';
import { useBrazeImpression } from '@hooks/use-braze-impression';
import { MenuItemCard } from '../card/menu-item-card';
import { OrderAtTableCarousel } from '../carousel/order-at-table-carousel';
import { Box, Flex } from '../grid';
import { ProductDetailModal } from '../product-detail-modal';
import { H2 } from '../typography';

const useBrazeFeaturedProductsContent = ({ title, featuredProducts }) => {
  const { BrazeStore } = useRootStore();
  const { featuredProductsCard } = BrazeStore;

  if (!featuredProductsCard) {
    return {
      title,
      featuredProducts,
    };
  }

  const featuredProductsWithBrazeData = [...featuredProductsCard.favourites, ...featuredProducts].filter(Boolean);

  return {
    title: featuredProductsCard?.title ?? null,
    featuredProducts: uniqBy(featuredProductsWithBrazeData, ({ id }) => id),
    brazeLogImpression: featuredProductsCard.brazeLogImpression,
    brazeLogClick: itemPlu => {
      if (featuredProductsCard.plus?.includes(itemPlu)) {
        featuredProductsCard.brazeLogClick();
      }
    },
  };
};

const PopularThisWeek = () => {
  const { MenuStore, ProductDetailsState } = useRootStore();
  const { menu, loadMenu, loading } = MenuStore;
  const menuFeaturedProducts = menu?.featuredProducts || [];

  const [productModalOpen, setProductModalOpen] = useState(false);

  const viewProduct = useCallback(
    (product, analyticsCategory) => {
      analytics.track(ANALYTICS_EVENTS.POPULAR_ITEM_CLICKED, {
        product_id: product.plu,
        name: product.name,
        category: product.productCategory?.name,
      });
      ProductDetailsState.viewProduct({ product, analyticsCategory });
      setProductModalOpen(true);
    },
    [ProductDetailsState, setProductModalOpen]
  );

  useEffect(() => {
    loadMenu();
  }, [loadMenu]);

  const featuredProductsContent = {
    title: `Popular this week`,
    featuredProducts: menuFeaturedProducts,
  };

  const { title, featuredProducts, brazeLogImpression, brazeLogClick } = useBrazeFeaturedProductsContent(
    featuredProductsContent
  );

  useBrazeImpression({ brazeLogImpression });

  return (
    <div>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center" mb="1rem" mx={0}>
        <H2>{title}</H2>
      </Flex>
      {productModalOpen && (
        <ProductDetailModal modalOpen={productModalOpen} handleModalClose={() => setProductModalOpen(false)} />
      )}

      <OrderAtTableCarousel impressionEvent={ANALYTICS_EVENTS.WELCOME_PAGE_POPULAR_SEEN} width="100%" pb={1}>
        {loading ? (
          <>
            <MenuItemCardSkeleton role="menuitem" tall shouldKeepVertical />
            <MenuItemCardSkeleton role="menuitem" tall shouldKeepVertical />
            <MenuItemCardSkeleton role="menuitem" tall shouldKeepVertical />
            <MenuItemCardSkeleton role="menuitem" tall shouldKeepVertical />
            <MenuItemCardSkeleton role="menuitem" tall shouldKeepVertical />
          </>
        ) : (
          featuredProducts.map(item => (
            <Box width="100%" height="100%" role="menuitem" key={item?.id}>
              <MenuItemCard
                item={{
                  ...item,
                  categoryName: item.productCategory?.name,
                  formattedDietaryPreferencesList: item.formattedDietaryPreferencesList,
                  formattedKilojoules: item.formattedKilojoules,
                  hasOtherSizes: item.hasOtherSizes,
                  hasVariablePrice: item.hasVariablePrice,
                  isAvailable: item.isAnySizeAvailable,
                  imageAlt: item.name,
                  plu: item.plu,
                }}
                onClick={() => {
                  viewProduct(item, item.title);
                  brazeLogClick?.(item.plu);
                }}
                shouldKeepVertical
                analyticsCategory={item.title}
                tall
                hideDescription
                variant="transparent"
                key={item.id}
              />
            </Box>
          ))
        )}
      </OrderAtTableCarousel>
    </div>
  );
};

const ComposedPopularThisWeek = observer(PopularThisWeek);
export { ComposedPopularThisWeek as PopularThisWeek };
