import { Popover } from '@material-ui/core';
import { restaurantPropTypes, selectedLocationPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '../../grid';
import { RestaurantDetails } from '../../restaurant-details';

const RestaurantCalloutWindow = ({
  coordinates,
  handleClose,
  linkText,
  onRestaurantClick,
  open,
  restaurant,
  showPopover,
  showRestaurantStatus,
}) => (
  <Popover
    open={open}
    anchorEl={showPopover}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <Box p={1}>
      <RestaurantDetails
        coordinates={coordinates}
        linkText={linkText}
        onRestaurantClick={onRestaurantClick}
        restaurant={restaurant}
        type="callout"
        showRestaurantStatus={showRestaurantStatus}
      />
    </Box>
  </Popover>
);

RestaurantCalloutWindow.propTypes = {
  coordinates: selectedLocationPropTypes,
  handleClose: PropTypes.func.isRequired,
  linkText: PropTypes.string.isRequired,
  onRestaurantClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  showPopover: PropTypes.shape({}),
  showRestaurantStatus: PropTypes.bool,
};

RestaurantCalloutWindow.defaultProps = {
  coordinates: undefined,
  showPopover: null,
  showRestaurantStatus: false,
};

export { RestaurantCalloutWindow };
